import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import {
  MaintenanceTab,
  MeterTab,
  WorkOrderTab,
  InventoriesTab,
  TeamsTab,
  AssetsTab,
} from '../../components/editCategoryTabs';
import './editCategory.scss';
import ReferenceNumber from '../../components/editCategoryTabs/referenceNumber/referenceNumber';
// import BusinessHoursTab from '../settings/businessHours/businessHours';
// import SLATab from '../settings/SLA/SLA';

export default function EditCategory () {
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('activeTab')) || 'teams-tab');

  useEffect(() => {
    localStorage.setItem('activeTab', JSON.stringify(key));
  }, [key]);

  return (
    <div className=" ml-2 title">
      <div className="float left">
        <h3 className="px-2">
          <b>Customization</b>
        </h3>
      </div>
      <div>
        <Tabs
          defaultActiveKey={key}
          id="uncontrolled-tab-example"
          className="nav nav-tabs custom_tabs"
          onSelect={k => setKey(k)}
        >
          <Tab eventKey="teams-tab" title="Teams">
            <TeamsTab />
          </Tab>
          <Tab eventKey="inventoriesTab" title="Inventories/Parts">
            <InventoriesTab />
          </Tab>
          <Tab eventKey="assets" title="Assets">
            <AssetsTab />
          </Tab>
          <Tab eventKey="maintenance" title="Maintenance">
            <MaintenanceTab />
          </Tab>
          <Tab eventKey="workorder" title="Work Order">
            <WorkOrderTab />
          </Tab>
          <Tab eventKey="meter" title="Meter">
            <MeterTab />
          </Tab>
          <Tab eventKey="referenceNumber" title="Reference Number">
            <ReferenceNumber />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
