/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */


import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

function PMSchedule() {
    const {
      maintenance
      } =useSelector(state => state.maintenance)
  return (
    <div  id="schedule-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-schedule-tab">
    <div className="card-body ">
        <div className="row">
            <div className=" col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">Start Date  <label className="font-danger m-0">*</label></label>
                <input
                        type="datetime-local"
                        className="form-control mb-2"
                        name="service_date"
                        id="meter_service_date"
                        placeholder="Start Date"
                        defaultValue={moment.utc(maintenance?.schedule?.start_date).format("YYYY-MM-DDTHH:mm")}
                        required
                        disabled
                         />

                         
            </div>
            <div className=" col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">End Date  <label className="font-danger m-0">*</label></label>
                <input
                        type="datetime-local"
                        className="form-control mb-2"
                        name="service_date"
                        id="meter_service_date"
                        placeholder="End Date"
                        defaultValue={moment.utc(maintenance?.schedule?.end_date).format("YYYY-MM-DDTHH:mm")}
                        required
                        disabled
                         />

                         
            </div>
        <div className=" col-lg-3 col-md-3 col-sm-4">
            <label className="font-weight-normal" >Interval  <label className="font-danger m-0">*</label>
                <i className="far fa-question-circle small-font gcolor" >
                    <span className=" tooltiptext" >
                        Maintenance Interval 
                    </span>
                </i>
            </label>
            <input name='interval' type="number" className="form-control mb-2"
                   placeholder="Every" id="meter_interval" min="0"
                   defaultValue={maintenance?.schedule?.frequency_number} 
                   disabled
                   required  />
            <div className="col-lg-12 col-sm-12 m-0"><span className="font-danger" id="meter_error"></span></div>
        
        </div>
        
        <div className=" col-lg-9 col-md-9 col-sm-8">
        <label className="font-weight-normal">Frequency  <label className="font-danger m-0">*</label></label>
        <select className="select-meter mb-2" name="frequency" 
            defaultValue={maintenance?.schedule?.frequency} 
            disabled
        id="maintenance_frequency" required>
        
            <option selected disabled>Select a Frequency</option>
            
            {
                [ 'week', 'month', 'year', 'day' ].map(freq =>
                    <option key={freq} value={freq}>{freq}(s)</option>
                )
            }

        </select>
        </div>
        </div>
    </div>

    
</div>
  )
}

export default PMSchedule