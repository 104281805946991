/* eslint-disable no-unneeded-ternary */
import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getLocationListByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/location-list/`);

  return assert(res, res.data, 'Retrieval of location by authentication failed', res);
};

const getLocationByAuthentication = async location => {
  const res = await http.get(`${BASE_URL}/location/?search=${location ? location : ''}`);
  return assert(res, res.data, 'Retrieval of location by its auth failed', res);
};

const getLocationByPagination = async data => {
  const res = await http.get(
    `${BASE_URL}/location/?page=${data.currentPage}&page_size=${data.rowPerPage}&search=${data.searchValue}`,
  );
  return assert(res, res.data, 'Retrieval of location by its auth failed', res);
};

const getLocationById = async LocationId => {
  const res = await http.get(`${BASE_URL}/location/${LocationId}/`);

  return assert(res, res.data, 'Retrieval of location  by its ID failed', res);
};

const createLocation = async LocationData => {
  const res = await http.post(`${BASE_URL}/location/`, LocationData);

  return assert(res, res.data, 'Creation of location  failed', res);
};
const getPagination = async data => {
  const res = await http.get(
    `${BASE_URL}/sub-location-list/${data.locationID}/?page=${data.currentPage}&page_size=${data.rowPerPage}&search=${data.searchValue}`,
  );
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const createSubLocation = async LocationData => {
  const res = await http.post(
    `${BASE_URL}/sub-location-list/${LocationData.id}/`,
    LocationData.data,
  );
  return assert(res, res.data, 'Creation of sub location  failed', res);
};
const deleteLocation = async LocationId => {
  const res = await http.delete(`${BASE_URL}/location/${LocationId}/`);

  return assert(res, LocationId, 'Deletion of location  failed', res);
};

const updateLocation = async LocationData => {
  const res = await http.put(`${BASE_URL}/location/${LocationData.id}/`, LocationData.data);

  return assert(res, res.data, 'Update of location  failed', res);
};
const updateSubLocation = async LocationData => {
  const res = await http.put(`${BASE_URL}/sub-location/${LocationData.id}/`, LocationData.data);

  return assert(res, res.data, 'Update of location  failed', res);
};

const deleteSubLocation = async LocationId => {
  const res = await http.delete(`${BASE_URL}/sub-location/${LocationId}/`);

  return assert(res, LocationId, 'Deletion of location  failed', res);
};

const getFloorList = async data => {
  const res = await http.get(
    `${BASE_URL}/floor-plan-list/${data.id}/?page=${data.currentPage}&page_size=${data.rowPerPage}&search=${data.searchValue}`,
  );
  return assert(res, res.data, 'Created floor failed', res);
};
const createFloor = async floor => {
  const res = await http.postImg(`${BASE_URL}/floor-plan-list/${floor.id}/`, floor.data);

  return assert(res, res.data, 'Created floor failed', res);
};

const getFloor = async floorId => {
  const res = await http.get(`${BASE_URL}/floor-plan/${floorId}/`);
  return assert(res, res.data, 'get floor failed', res);
};
const deleteFloor = async id => {
  const res = await http.delete(`${BASE_URL}/floor-plan/${id}/`);
  return assert(res, id, 'Created floor failed', res);
};

const updateFloor = async floor => {
  const res = await http.put(`${BASE_URL}/floor-plan/${floor.id}/`, floor.data);
  return assert(res, res.data, 'Updated floor failed', res);
};

const getSubLocationHistoryById = async data => {
  let res = '';
  if (data.id) {
    res = await http.get(
      `${BASE_URL}/sub-location-history/${data.id}/?search=${data.searchValue}&?page=${data.currentPage}&page_size=${data.rowPerPage}`,
    );
  }
  return assert(res, res.data, 'Retrieval of location history by its ID failed', res);
};

const LocationService = {
  getLocationByPagination,
  getFloor,
  getLocationByAuthentication,
  getLocationById,
  createLocation,
  deleteLocation,
  updateLocation,
  getLocationListByAuthentication,
  createSubLocation,
  updateSubLocation,
  deleteSubLocation,
  createFloor,
  deleteFloor,
  updateFloor,
  getSubLocationHistoryById,
  getPagination,
  getFloorList,
};

export default LocationService;
