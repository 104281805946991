import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import workOrderService from './workOrderService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Assets
  workOrders: [],
  workOrder: {},
  TicketActivity :[],
  fileExport  : [],
  workOrdrSummary  : null,
  workOrderApproval  : null,

  tab : "WorkOrder-tab",
  searchValue: "",
  // modals
  workOrderModalDelete: false,
  workOrderModalUpdate: false,
  workOrderModalCreate: false,
  isAssetExported: false,
  workOrderModalAccept: false,
  workOrderModalDecline: false,
  workOrderApprovalModalAccept: false,
  workOrderApprovalModalDecline: false,
  reason: "",
  approvalReason: "",
  statusType: "",


  // filter data
  site: null,
  sublocation: null,
  filterCategory: null,
  type: null,

  // WorkOrder fields
  WorkOrderId : null,
  ticketType : "",
  title: "",
  description: "",
  priority: "",
  category: "",
  workOrderType: "",
  signature: null,
  start_date : "",
  due_finish : "",
  end_date: "",
  completed_date : "",
  completionNotes : "",
  startDate : "",
  workOrderStartDate : "",
  workOrderStartDateTime : "",
  assigned_to: [],
  assigned: "",
  asset: "",
  location: "",
  time : "",
  status : "",
  frequency_number: "",
  frequency:"",
  attachments: [],
  files: [],
  dueDate : "",
  fixedDate : "",
  isShowCost : false,
  meterId: null,
  currentReading:"",
  uploadMeterAttachment:"",
  uploadedMeterAttachment:"",
  isSLAEnabled : true,
  rquestor: "",
  approvalDate: "",
  approvalStatus: "",
  
  staff: null,
  filterStatus: null,
  

    // pagination
    currentPage: 1,
    rowPerPage: 10,

  

  // success states
  isWorkOrderGetSuccess: false,
  isWorkOrderCreateSuccess: false,
  isWorkOrderDeleteSuccess: false,
  isWorkOrderUpdateSuccess: false,
  isDeleteTicketAttachmentSuccess: false,
 

  // loading states
  isWorkOrderCreateLoading: false,
  isWorkOrderGetLoading: false,
  isWorkOrdersGetLoading: false,
  isWorkOrderDeleteLoading: false,
  isWorkOrderUpdateLoading: false,


  // error states
  isWorkOrderGetError: false,
  isWorkOrderCreateError: false,
  isWorkOrderDeleteError: false,
  isWorkOrderUpdateError: false,

  isWorkOrderGetStatus: false,
  isWorkOrderGetApproveStatus: false,

};

export const createWorkOrder = createAsyncThunk('createWorkOrder', async (data, thunkAPI) => {
  const createWorkOrderRes = await smartTryCatch(workOrderService.createWorkOrder, data, thunkAPI);
  return createWorkOrderRes;
});

export const getWorkOrdersByAuthentication = createAsyncThunk(
  'getWorkOrdersByAuthentication',
  async (data, thunkAPI) => {
    const getWorkOrders = await smartTryCatch(
      workOrderService.getWorkOrdersByAuthentication,
      data,
      thunkAPI,
    );
    return getWorkOrders;
  },
);
export const getPagination = createAsyncThunk('getPaginationForWo', async (data, thunkAPI) => {
  const getPM = await smartTryCatch(workOrderService.getPagination, data, thunkAPI);
  return getPM;
});

export const getWorkOrderSummary = createAsyncThunk('getWorkOrderSummary', async (data, thunkAPI) => {
  const getWorkOrder = await smartTryCatch(workOrderService.getWorkOrderSummary, data, thunkAPI);
  return getWorkOrder;
});
export const AcceptAndDecline = createAsyncThunk('AcceptAndDecline', async (data, thunkAPI) => {
  const getWorkOrder = await smartTryCatch(workOrderService.AcceptAndDecline, data, thunkAPI);
  return getWorkOrder;
});

export const ApproveAndReject = createAsyncThunk('ApproveAndReject', async (data, thunkAPI) => {
  const getWorkOrder = await smartTryCatch(workOrderService.ApproveAndReject, data, thunkAPI);
  return getWorkOrder;
});

export const getWorkOrderById = createAsyncThunk('getWorkOrderById', async (data, thunkAPI) => {
  const getWorkOrder = await smartTryCatch(workOrderService.getWorkOrderById, data, thunkAPI);
  return getWorkOrder;
});

export const getWorkOrderByTicketNumber = createAsyncThunk('getWorkOrderByTicketNumber', async (data, thunkAPI) => {
  const getWorkOrder = await smartTryCatch(workOrderService.getWorkOrderByTicketNumber, data, thunkAPI);
  return getWorkOrder;
});

export const deleteWorkOrder = createAsyncThunk('deleteWorkOrder', async (data, thunkAPI) => {
  const deleteWorkOrderRes = await smartTryCatch(workOrderService.deleteWorkOrder, data, thunkAPI);
  return deleteWorkOrderRes;
});


export const updateWorkOrder = createAsyncThunk('updateWorkOrder', async (data, thunkAPI) => {
  const updateWorkOrderRes = await smartTryCatch(workOrderService.updateWorkOrder, data, thunkAPI);
  return updateWorkOrderRes;
});

export const exportWorkOrder = createAsyncThunk('exportWorkOrder', async (data, thunkAPI) => {
  const exportWorkOrderRes = await smartTryCatch(workOrderService.exportWorkOrder, data, thunkAPI);
  return exportWorkOrderRes;
});
export const getDueDate = createAsyncThunk('getDueDate', async (data, thunkAPI) => {
  const getDueDateRes = await smartTryCatch(workOrderService.getDueDate, data, thunkAPI);
  return getDueDateRes;
});
export const addAttachmentTicket = createAsyncThunk('addAttachment', async (data, thunkAPI) => {
  const addAttachmentRes = await smartTryCatch(workOrderService.addAttachment, data, thunkAPI);
  return addAttachmentRes;
});
export const editAttachmentTicket = createAsyncThunk('editAttachment', async (data, thunkAPI) => {
  const EditAttachmentRes = await smartTryCatch(workOrderService.editAttachment, data, thunkAPI);
  return EditAttachmentRes;
});
export const deleteAttachmentTicket = createAsyncThunk('deleteAttachment', async (data, thunkAPI) => {
  const deleteAttachmentRes = await smartTryCatch(workOrderService.deleteAttachment, data, thunkAPI);
  return deleteAttachmentRes;
});

// export const deleteTicketAttachment = createAsyncThunk('deleteTicketAttachment', async (data, thunkAPI) => {
//   const deleteTicketAttachmentRES = await smartTryCatch(workOrderService.deleteTicketAttachment, data, thunkAPI);
//   return deleteTicketAttachmentRES;
// });
export const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isWorkOrderCreateError = false;
      state.isWorkOrderCreateSuccess = false;
      state.isWorkOrderCreateLoading = false;
      state.isWorkOrderGetError = false;
      state.isWorkOrderGetSuccess = false;
      state.isWorkOrderGetLoading = false;
      state.isWorkOrderDeleteError = false;
      state.isWorkOrderDeleteSuccess = false;
      state.isWorkOrderDeleteLoading = false;
      state.isWorkOrderUpdateError = false;
      state.isWorkOrderUpdateSuccess = false;
      state.isWorkOrderUpdateLoading = false;
      state.isWorkOrdersGetLoading = false;
      state.isDeleteTicketAttachmentSuccess = false;
      state.isWorkOrderGetStatus = false;
      state.isWorkOrderGetApproveStatus = false;
      state.fileExport = []

      // state.workOrder = null;
    },
    openWorkOrderModalCreate: (state) => {
      state.workOrderModalCreate = true;
    },
    closeWorkOrderModalCreate: (state) => {
      state.workOrderModalCreate = false;
      state.attachments = []
    },
    openWorkOrderModalUpdate: (state) => {
      state.workOrderModalUpdate = true;
    },
    closeWorkOrderModalUpdate: (state) => {
      state.workOrderModalUpdate = false;
      state.files = []
      state.attachments = []
    },
    openWorkOrderModalDelete: (state) => {
      state.workOrderModalDelete = true;
    },
    closeWorkOrderModalDelete: (state) => {
      state.workOrderModalDelete = false;
    },
    openWorkOrderModaAccept: (state) => {
      state.workOrderModalAccept = true;
    },
    closeWorkOrderModalAccept: (state) => {
      state.workOrderModalAccept = false;
    },
    openWorkOrderApprovalModalAccept: (state) => {
      state.workOrderApprovalModalAccept = true;
    },
    closeWorkOrderApprovalModalAccept: (state) => {
      state.workOrderApprovalModalAccept = false;
    },
    openWorkOrderModalDecline: (state) => {
      state.workOrderModalDecline = true;
    },
    closeWorkOrderModalDecline: (state) => {
      state.workOrderModalDecline = false;
    },
    openWorkOrderApprovalModalDecline: (state) => {
      state.workOrderApprovalModalDecline = true;
    },
    closeWorkOrderApprovalModalDecline: (state) => {
      state.workOrderApprovalModalDecline = false;
    },
    setReason: (state, action) => {
      state.reason = action.payload;
    },
    setApprovalReason: (state, action) => {
      state.approvalReason = action.payload;
    },
    setStatusType: (state, action) => {
      state.statusType = action.payload;
    },
    setWorkOrder: (state, action) => {
      state.workOrder = action.payload;
    },
    setWorkOrderId: (state, action) => {
      state.workOrderId = action.payload;
    },
    setTab : (state , action) => {
      state.tab = action.payload
    },
    setTicketType : (state, action) => {
      state.ticketType = action.payload;
    },
    setTitle : (state, action) => {
      state.title = action.payload;
    },
    setDescription : (state, action) => {
      state.description = action.payload;
    },
    setPriority : (state, action) => {
      state.priority = action.payload;
    },
    setCategory : (state, action) => {
      state.category = action.payload;
    },
    setApprovalDate : (state, action) => {
      state.approvalDate = action.payload;
    },
    setApprovalStatus : (state, action) => {
      state.approvalStatus = action.payload;
    },
    setWorkOrderType : (state, action) => {
      state.workOrderType = action.payload;
    },
    setSignature : (state, action) => {
      state.signature = action.payload;
    },
    setEndDate : (state, action) => {
      state.end_date = action.payload;
    },
    setStartedDate : (state, action) => {
      state.start_date = action.payload;
    },
    setFrequencyNumber : (state, action) => {
      state.frequency_number = action.payload;
    },
    setFrequency : (state, action) => {
      state.frequency = action.payload;
    },
    setAssignedTo : (state, action) => {
      state.assigned_to = action.payload;
    },
    setAssigned : (state, action) => {
      state.assigned = action.payload
    },
    setLocation : (state, action) => {
      state.location = Number(action.payload);
    },
    setAsset : (state, action) => {
      state.asset = Number(action.payload);
    },
    setTime : (state, action) => {
      state.time = action.payload;
    },
    setStatus : (state, action) => {
      state.status = action.payload;
    },
    setDueFinish : (state, action) => {
      state.due_finish= action.payload;
    },
    setStartDate : (state, action) => {
      state.startDate = action.payload;
    },
    setSWorkOrderStartDate : (state, action) => {
      state.workOrderStartDate = action.payload;
    },
    setSWorkOrderStartDateTime : (state, action) => {
      state.workOrderStartDateTime = action.payload;
    },
    setCompletedDate : (state, action) => {
      state.completed_date = action.payload;
    },
    setCompletionNotes : (state, action) => {
      state.completionNotes = action.payload;
    },
    setSearchValue : (state, action) => {
      state.searchValue = action.payload;
    },
    addAttachment: (state, action) => {
      state.attachments.push(...action.payload);
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    getAttachment: (state, action) => {
      state.attachments= action.payload;
    },
    setFixedDate : (state, action) => {
      state.fixedDate = action.payload;
    },
    setDueDate : (state, action) => {
      state.dueDate = action.payload;
    },
    setIsShowCost : (state, action) => {
      state.isShowCost = action.payload;
    },
    setIsSLAEnabled : (state, action) => {
      state.isSLAEnabled = action.payload;
    },
    setRequestor: (state, action) => {
      state.rquestor = action.payload;
    },
    // store the filter value
    setWOFilterSite: (state, action) => {
      state.site = action.payload;
    },
    setWOFilterSublocation: (state, action) => {
      state.sublocation = action.payload;
    },
    setWOFilterCategory: (state, action) => {
      state.filterCategory = action.payload;
    },
    setWOFiltertype: (state, action) => {
      state.type = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    setMeterId: (state, action) => {
      state.meterId = action.payload;
    },
    setCurrentReading: (state, action) => {
      state.currentReading = action.payload;
    },
    setUploadMeterAttachment: (state, action) => {
      state.uploadMeterAttachment = action.payload;
    },
    setUploadedMeterAttachment: (state, action) => {
      state.uploadedMeterAttachment = action.payload;
    },

    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    
    resetWorkOrder: (state) => {
      state.workOrderId = null
      state.ticketType = ""
      state.title= ""
      state.description= ""
      state.priority= ""
      state.category= ""
      state.workOrderType= ""
      state.signature= null
      state.end_date= ""
      state.assigned_to= []
      state.assigned = ""
      state.asset= ""
      state.location= ""
      state.time = ""
      state.status = ""
      state.frequency_number = ""
      state.frequency = ""
      state.due_finish = ""
      state.start_date = ""
      state.completed_date = ""
      state.completionNotes = ""
      state.startDate = ""
      state.workOrderStartDate = ""
      state.workOrderStartDateTime = ""
      state.attachments = []
      state.files = []
      state.dueDate = ""
      state.fixedDate = ""
      state.meterId = null
      state.isShowCost= false
      state.currentReading= ""
      state.uploadMeterAttachment= ""
      state.uploadedMeterAttachment= ""
      state.isSLAEnabled= true
      state.rquestor= ""
      state.workOrderModalDelete= false
      state.workOrderModalAccept= false
      state.workOrderModalDecline= false
      state.reason= ""
      state.approvalStatus= ""
      state.approvalDate= ""
      state.staff= null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWorkOrder.pending, (state) => {
        state.isWorkOrderCreateLoading = true;
        state.isWorkOrderCreateSuccess = false;
        state.isWorkOrderCreateError = false;
      })
      .addCase(createWorkOrder.fulfilled, (state, action) => {
        state.isWorkOrderCreateLoading = false;
        state.isWorkOrderCreateSuccess = true;
        state.isWorkOrderCreateError = false;
        state.workOrders.results = [action.payload ,...state.workOrders.results ];
      })
      .addCase(createWorkOrder.rejected, (state) => {
        state.isWorkOrderCreateLoading = false;
        state.isWorkOrderCreateSuccess = false;
        state.isWorkOrderCreateError = true;
      })
      .addCase(getWorkOrdersByAuthentication.pending, (state) => {
        state.isWorkOrdersGetLoading = true;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = false;
      })
      .addCase(getWorkOrdersByAuthentication.fulfilled, (state, action) => {
        state.isWorkOrdersGetLoading = false;
        state.isWorkOrderGetSuccess = true;
        state.isWorkOrderGetError = false;
        state.workOrders = action.payload;
      })
      .addCase(getWorkOrdersByAuthentication.rejected, (state) => {
        state.isWorkOrdersGetLoading = false;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = true;
      }).addCase(getWorkOrderSummary.pending, (state) => {
        state.isWorkOrderGetLoading = true;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = false;
      })
      .addCase(getWorkOrderSummary.fulfilled, (state, action) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetSuccess = true;
        state.isWorkOrderGetError = false;
        state.workOrdrSummary = action.payload;
      })
      .addCase(getWorkOrderSummary.rejected, (state) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = true;
      })
      .addCase(AcceptAndDecline.pending, (state) => {
        state.isWorkOrderGetLoading = true;
        state.isWorkOrderGetStatus = false;
        state.isWorkOrderGetError = false;
      })
      .addCase(AcceptAndDecline.fulfilled, (state) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetStatus = true;
        state.isWorkOrderGetError = false;
        // state.workOrderApproval = action.payload;
      })
      .addCase(AcceptAndDecline.rejected, (state) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetStatus = false;
        state.isWorkOrderGetError = true;
      })
      .addCase(ApproveAndReject.pending, (state) => {
        state.isWorkOrderGetLoading = true;
        state.isWorkOrderGetApproveStatus = false;
        state.isWorkOrderGetError = false;
      })
      .addCase(ApproveAndReject.fulfilled, (state, action) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetApproveStatus = true;
        state.isWorkOrderGetError = false;
        state.workOrderApproval = action.payload;
      })
      .addCase(ApproveAndReject.rejected, (state) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetApproveStatus = false;
        state.isWorkOrderGetError = true;
      })
      .addCase(getWorkOrderById.pending, (state) => {
        state.isWorkOrderGetLoading = true;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = false;
      })
      .addCase(getWorkOrderById.fulfilled, (state, action) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetSuccess = true;
        state.isWorkOrderGetError = false;
        state.workOrder = action.payload;
      })
      .addCase(getWorkOrderById.rejected, (state) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = true;
      })
      .addCase(getWorkOrderByTicketNumber.pending, (state) => {
        state.isWorkOrderGetLoading = true;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = false;
      })
      .addCase(getWorkOrderByTicketNumber.fulfilled, (state, action) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetSuccess = true;
        state.isWorkOrderGetError = false;
        state.workOrder = action.payload;
      })
      .addCase(getWorkOrderByTicketNumber.rejected, (state) => {
        state.isWorkOrderGetLoading = false;
        state.isWorkOrderGetSuccess = false;
        state.isWorkOrderGetError = true;
      })
      .addCase(deleteWorkOrder.pending, (state) => {
        state.isWorkOrderDeleteLoading = true;
        state.isWorkOrderDeleteSuccess = false;
        state.isWorkOrderDeleteError = false;
      })
      .addCase(deleteWorkOrder.fulfilled, (state, action) => {
        state.isWorkOrderDeleteLoading = false;
        state.isWorkOrderDeleteSuccess = true;
        state.isWorkOrderDeleteError = false;
        state.workOrders.results = state.workOrders.results.filter((workOrder) => workOrder.id !== +action.payload);
      })
      .addCase(deleteWorkOrder.rejected, (state) => {
        state.isWorkOrderDeleteLoading = false;
        state.isWorkOrderDeleteSuccess = false;
        state.isWorkOrderDeleteError = true;
      })
      .addCase(addAttachmentTicket.pending, (state) => {
        state.isDeleteTicketAttachmentSuccess = false;
      })
      .addCase(addAttachmentTicket.fulfilled, (state , action) => {
        state.isDeleteTicketAttachmentSuccess = true;
        state.attachments = [...state.attachments, action.payload]

      })
      .addCase(addAttachmentTicket.rejected, (state) => {
        state.isDeleteTicketAttachmentSuccess = false;
      })
      .addCase(editAttachmentTicket.pending, (state) => {
        state.isDeleteTicketAttachmentSuccess = false;
      })
      .addCase(editAttachmentTicket.fulfilled, (state , action) => {
        state.isDeleteTicketAttachmentSuccess = true;
        state.attachments = state.attachments.map(attachment => attachment.id === action.payload.id ? action.payload : attachment)

      })
      .addCase(editAttachmentTicket.rejected, (state) => {
        state.isDeleteTicketAttachmentSuccess = false;
      })
      .addCase(deleteAttachmentTicket.pending, (state) => {
        state.isDeleteTicketAttachmentSuccess = false;
      })
      .addCase(deleteAttachmentTicket.fulfilled, (state , action) => {
        state.isDeleteTicketAttachmentSuccess = true;
        state.attachments = state.attachments.filter(attachment => attachment.id !== action.payload.attachmentId)

      })
      .addCase(deleteAttachmentTicket.rejected, (state) => {
        state.isDeleteTicketAttachmentSuccess = false;
      })
      .addCase(updateWorkOrder.pending, (state) => {
        state.isWorkOrderUpdateLoading = true;
        state.isWorkOrderUpdateSuccess = false;
        state.isWorkOrderUpdateError = false;
      })
      .addCase(updateWorkOrder.fulfilled, (state, action) => {
        state.isWorkOrderUpdateLoading = false;
        state.isWorkOrderUpdateSuccess = true;
        state.isWorkOrderUpdateError = false;
        state.workOrders.results = state.workOrders?.results?.map((workOrder) =>
        workOrder.id === action.payload.id ? action.payload : workOrder,
        );
        state.workOrder =  action.payload
        state.files =  [];
      })
      .addCase(updateWorkOrder.rejected, (state) => {
        state.isWorkOrderUpdateLoading = false;
        state.isWorkOrderUpdateSuccess = false;
        state.isWorkOrderUpdateError = true;
      }).addCase(getPagination.pending, (state) => {
      state.isWorkOrdersGetLoading = true;
      state.isWorkOrderGetSuccess = false;
      state.isWorkOrderGetError = false;
    })
    .addCase(getPagination.fulfilled, (state, action) => {
      state.isWorkOrdersGetLoading = false;
      state.isWorkOrderGetSuccess = true;
      state.isWorkOrderGetError = false;
      state.workOrders = action.payload;
    })
    .addCase(getPagination.rejected, (state) => {
      state.isWorkOrdersGetLoading = false;
      state.isWorkOrderGetSuccess = false;
      state.isWorkOrderGetError = true;
    }) 
    .addCase(exportWorkOrder.pending, (state) => {
      state.isWorkOrderExported = false;
    })
    .addCase(exportWorkOrder.fulfilled, (state , action) => {
      state.isWorkOrderExported = true;
      state.fileExport = action.payload
    })
    .addCase(exportWorkOrder.rejected, (state) => {
      state.isWorkOrderExported = false;
    })
    .addCase(getDueDate.fulfilled, (state , action) => {
      state.dueDate = action.payload
    })
},
});

export const {
reset,
openWorkOrderModalCreate,
closeWorkOrderModalCreate,
openWorkOrderModalDelete,
closeWorkOrderModalDelete,
openWorkOrderModaAccept,
closeWorkOrderModalAccept,
openWorkOrderModalDecline,
closeWorkOrderModalDecline,
openWorkOrderApprovalModalAccept,
closeWorkOrderApprovalModalAccept,
openWorkOrderApprovalModalDecline,
closeWorkOrderApprovalModalDecline,
setApprovalReason,
setApprovalDate,
setApprovalStatus,
setReason,
setStatusType,
setWorkOrderId,
setTab,
setTicketType,
setTitle,
setDescription,
setPriority,
setCategory,
setWorkOrderType,
setEndDate,
setAssignedTo,
setAssigned,
setLocation,
setAsset,
setTime,
setStatus,
setSignature,
setWorkOrder,
setStartedDate,
setDueFinish,
setCompletedDate,
setCompletionNotes,
openWorkOrderModalUpdate,
closeWorkOrderModalUpdate,
resetWorkOrder,
setFrequencyNumber,
setFrequency,
setSearchValue,
addAttachment,
setFiles,
getAttachment,
setStartDate,
setFixedDate,
setDueDate,
setSWorkOrderStartDate,
setSWorkOrderStartDateTime,
setIsShowCost,
setIsSLAEnabled,
setRequestor,
setWOFilterSite,
setWOFilterSublocation,
setWOFilterCategory,
setWOFiltertype,
setCurrentPage,
setRowPerPage,
setMeterId,
setCurrentReading,
setUploadMeterAttachment,
setUploadedMeterAttachment,
setStaff,
setFilterStatus
} = workOrderSlice.actions;

export default workOrderSlice.reducer;
