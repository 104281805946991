/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-unsafe-optional-chaining */


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createTicketinv, reset } from '../../../../../redux/features/ticketInventory/ticketInventorySlice';
import InvTable from './invTable';
import { getInventorysByAuthentication, getPagination, setSearchValue } from '../../../../../redux/features/inventory/inventorySlice';

function Inventory({tktId}) {
  const inventorys =   useSelector(state => state.inventory?.inventorys?.results)
  const {searchValue} =   useSelector(state => state.inventory)
  const {ticketinvs , isTicketinvCreateSuccess} =   useSelector(state => state.ticketinv)  
  const [myInv, setMyInv] = useState([])

  const [close, setClose] = useState(true)
  const [isError, setIsError] = useState(false)
  const [invId, setInvId] = useState()
  const addInInvArr = (id)=>{
  if (myInv.length > 0)  { 
      if (myInv.includes(id)) {
          const list = myInv.filter(num => num !== id)
          setMyInv(list)
      }else {
          setMyInv([...myInv , id])
      }
  }
  else{
   setMyInv([id])
    }  
    setIsError(false)
  }
  const data = {
      "used": 1,
      "inventory": myInv
  }

  const dispatch = useDispatch()

  const addPart = async ()=>{
      if (myInv.length > 0) {
         await dispatch(createTicketinv({id : tktId ,  data}))
         await dispatch(getInventorysByAuthentication());
          dispatch(reset())
          setClose(true)
      }else{
        setIsError(true)
      }
  }

  useEffect(()=>{
    if (isTicketinvCreateSuccess) {
      setMyInv([])
    }
  }, [isTicketinvCreateSuccess])
  const handelInvId = (idNum)=>{
    setInvId(idNum)
  }

  const totalCost = ticketinvs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.total_cost,
    0,
  ).toFixed(2);
  
  const handelSearch = (e)=>{
    dispatch(setSearchValue(e.target.value))
    const searchValues = {
      site: "",
      sublocation: "",
      filterCategory: "",
      rowPerPage: 10, 
      currentPage: 1, 
      searchValue: e.target.value
    }
    dispatch(getPagination(searchValues))  }
  
return (
<div  id="inventory-tab" role="tabpanel" aria-labelledby="custom-tabs-two-inventory-tab">
             
              <div >
              <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 p-0" >
              </div>

              <div className="card-title p-3" style={{float:"right"}}>
              <b>Total Parts Cost</b>
              <strong className="red" id="total_parts_cost">

              {" "}${totalCost}

              </strong>
              <input type="hidden" id="value_total_parts"
              value="0"
              />
              </div>
              <div className="card-body">
              <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 p-0" >
              <div className="card-header m-0 p-0 gcolor">
              <span >Associated Parts</span>

              </div>
              <div className="card-body p-0" style={{overflowX : "auto" , marginBottom:"20px"}}>
              <table className="table table-borderless" style={{background: "transparent", border: "none"}}>
              <thead>
                  <tr style={{textAlign: "center"}}>
                  <th scope="col-1" className="pl-0 col-1">#</th>
                  <th scope="col-5" className="pl-0 col-4">Sparepart</th>
                  <th scope="col-2" className="pl-0 col-2">Balance</th>
                  <th scope="col-3" className="pl-1 col-5">Used</th>
                  <th scope="col-1" className="pl-0 col-1">Cost</th>
                  <th scope="col-1" className="pl-0 col-1"></th>
                  </tr>
              </thead>
              <tbody id="append-part">
                  {ticketinvs &&ticketinvs.length > 0  && ticketinvs?.map(inv =>(
                      <InvTable 
                      key={inv.id} inv = {inv}  
                      tktId={tktId}
                      myInv={myInv}
                      setMyInv={setMyInv}
                      invId ={invId}
                      handelInvId ={handelInvId}
                      />
                  ))}
                  
              </tbody>
              </table>
              </div>
              <span onClick={()=> {setClose(false); setIsError(false) }}  
              title="Add Part" className=" btn primary-btn"

                      style={{fontWeight: 'normal'}}
              >
                  Add Part
              </span>
              
              
              {!close && <div className="modal modal-space" style={{display : "block"}}
          id="modal-parts" data-backdrop="false" tabIndex="1" role="dialog">
  <div className="modal-dialog ">
    <div className="modal-content">
      <div className="modal-header block">
        <h4 className="modal-title">Select parts</h4>
        <button type="button" className="close" onClick={()=> setClose(true)} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        
      </div>
      <div className="modal-body py-0" style={{height:"400px", overflow: "auto"}}>
      <div className='mt-3'><input
                  value = {searchValue}
                  type="text"
                  id="location-list-popup-search"
                  placeholder="Search"
                  className="search-bar mb-3 w-100"
                  onChange={handelSearch}
                /></div>
      {isError &&  <p className='red'>Please Select A Part First</p>}
          <table className="table table-borderless mb-0"
           style={{background: "transparent", border: "none"}}>
              <thead>
                <tr style={{ color:"#8e8e8e"}}>
                  <th scope="col-1" className="pl-0 col-1">#</th>
                  <th scope="col-5" className="col-6">Sparepart</th>
                  <th scope="col-1" className="pl-0 col-1">Inventory</th>
                 
                </tr>
              </thead>
              <tbody id="dialog-append-part">
                  {inventorys && inventorys?.map(inv =>{
                      let rowClass = ""
                      let rowDisabled=""
                      ticketinvs?.map(v =>{
                        if ( v.id === inv.id) {
                          rowClass = "clicked-row"
                          rowDisabled = "disabled"
                        }
                      })
                      myInv?.map(v =>{
                        if ( v === inv.id) {
                          rowClass = "add-remove"
                        }
                      })
                    return  <tr className={`${rowClass} ${rowDisabled}`} 
                                key={inv.id} id ={inv.id}
                                
                      onClick={()=>addInInvArr(inv.id)}
                     >
                      <td scope="row" className="pl-0" id="part-check-{{part.id}}" style={{textAlign: "center"}}>{inv.inventory_id}</td>
                      <td>{inv.name}</td>
                      <td id="dialog-total-inventory-{{part.id}}" scope="row" 
                      className="pl-0" style={{textAlign: "center"}}>{inv.total}</td>
                    </tr>  
              })}
              </tbody>
            </table>
            {inventorys?.length ===  0 && <h4>No Results Found</h4>}
      </div>
      <div className="modal-footer col-12">
          <div className="col-12 float-right">
          <button onClick={addPart} type="button" className="btn btn-primary">Add</button>
          <button type="button" className="btn secondary-btn ml-3" onClick={()=> {
            setClose(true)
            setMyInv([])}}  aria-label="Close">Cancel</button>
          </div>
      </div>
    </div>
  </div>
</div> }

              </div>
              </div>
              </div>
</div>
)
}

export default Inventory
