/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { updateLocation, openSiteModeul } from '../../redux/features/location/locationSlice';

export default function UpdateLocationPage ({ show, closeUpdateLocation }) {
  const dispatch = useDispatch();
  // const [hasTenants, setHasTenants] = useState(false);
  const { showLocationModalUpdate, location } = useSelector(state => state.location);
  const [loc, setLoc] = useState(location);
  const [management, setManagement] = useState(location?.management ? location?.management : null);

  // useEffect(() => {
  //   if(location) {
  //     setLoc(location);
  //     setManagement(location?.management);
  //   }
  //   console.log(loc)
  // }, [location]);

  // useEffect(() => {
  //   // eslint-disable-next-line object-shorthand
  //   setLoc({ ...location, management: management });
  // }, [management]);

  useEffect(() => {
    if(location) {
      setLoc(location);
      setManagement(location?.management);
    }
    // console.log(loc)
  }, [location]);

  function handleLocationUpdate (e) {
    e.preventDefault();
    // set the nwe management data to the location state
    const info = {
      id: location.id,
      // data: loc,
      data: {...loc, management},
    };
    dispatch(updateLocation(info));
    // dispatch(closeLocationModalUpdate());
    // handleClose();
    closeUpdateLocation();
    dispatch(openSiteModeul())
  }

  function handleCancel() {
    closeUpdateLocation();
    dispatch(openSiteModeul())
  }

  const formateDate = date => {
    const inputDate = new Date(date);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    // const hours = String(inputDate.getHours()).padStart(2, '0');
    // const minutes = String(inputDate.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };


//   function resetInputField(){
//     document.getElementById("site_name_new").value="";
//     document.getElementById("site_street_new").value="";
//     document.getElementById("site_district_new").value="";
//     document.getElementById("site_city_new").value="";
//     document.getElementById("site_state_new").value="";
//     document.getElementById("site_postcode_new").value="";
//     document.getElementById("tenant_name_new").value="";
//     document.getElementById("owner_name_new").value="";
//     document.getElementById("site_category").value="";
//     document.getElementById("rent_start_date").value="";
//     document.getElementById("rent_end_date").value="";
//     document.getElementById("description").value="";
//   }

//   const { profile } = useSelector(state => state.company);
//   const  {locationsCount}  = useSelector(state => state.location);
//   const limitOfLocations = profile?.subscription?.allowed_locations
//   const isLocationLimitEnabled = profile?.subscription?.enable_locations_limit

//   const handleCreateLocation = e => {
//     e.preventDefault();
//     if (isLocationLimitEnabled) {
//       if (locationsCount >= limitOfLocations) {
//         toast.error('You have reached the maximum number of allowed locations', {
//           toastId: 'allowedlocationsError',
//         });
//       }else{
//         dispatch(setSearchBar(true))
//         const info = locationinfo;
//         info.management = management || null;
//         dispatch(createLocation(info));
//         resetInputField()
//         showAdd();
//       }
//     }else{
//       dispatch(setSearchBar(true))
//       const info = locationinfo;
//       info.management = management || null;
//       dispatch(createLocation(info));
//       resetInputField()
//       showAdd();
//     }
 
//   };

  return (
    <div className="card m-4 p-2 none" id="add-site" style={{ display: show ? 'block' : 'none' }}>
      <div className="row px-3 pt-3"/>
      <form onSubmit={handleLocationUpdate}>
        <div className="card-header">
          <div className="card-title">
            <h4 className="">
              <b>Edit Location</b>
            </h4>
          </div>
        </div>
        <div className="card-body">
          <div className="col-12">
            <div className="row">
              <div className="location_error_msg">
                <span className="font-danger"> </span>
              </div>

              <div className="col-8">
                <label className="font-weight-normal" htmlFor="site_name_new">
                Location/Branch{' '}
                  <label className="m-0 font-danger" htmlFor="site_name_new">
                    *
                  </label>
                </label>
                <input
                  name="site_name"
                  type="text"
                  className="form-control mb-2"
                  required
                  placeholder="Location/Branch"
                  id="site_name"
                  value={loc?.site}
                  onChange={e => setLoc({ ...loc, site: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_street_new">
                  Street
                </label>
                <input
                  name="site_street"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Street (Optional)"
                  value={loc?.street}
                  onChange={e => setLoc({ ...loc, street: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_district_new">
                  District
                </label>
                <input
                  name="site_district"
                  type="text"
                  className="form-control mb-2"
                  placeholder="District (Optional)"
                  value={loc?.district}
                  onChange={e => setLoc({ ...loc, district: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_city_new">
                  City
                </label>
                <input
                  name="site_city"
                  type="text"
                  className="form-control mb-2"
                  placeholder="City (Optional)"
                  value={loc?.city}
                  onChange={e => setLoc({ ...loc, city: e.target.value })}
                />
              </div>
              <div className="col-4">
                <label className="font-weight-normal" htmlFor="site_state_new">
                  State
                </label>
                <input
                  name="site_state"
                  type="text"
                  className="form-control mb-2"
                  placeholder="State (Optional)"
                  value={loc?.state}
                  onChange={e => setLoc({ ...loc, state: e.target.value })}
                />
              </div>

              <div className="col-4">
                <label className="font-weight-normal text-nowrap" htmlFor="site_postcode_new">
                  Postal Code
                </label>
                <input
                  name="site_postcode"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Postal Code (Optional)"
                  value={loc?.postal_code}
                  onChange={e => setLoc({ ...loc, postal_code: e.target.value })}
                />
              </div>

              <div className="col-lg-12 col-sm-12 m-0">
                <span className="font-danger" id="location_name_error">
                  {' '}
                </span>
              </div>
            </div>
          </div>
        </div>

        <section style={{ marginTop: '-50px' }}>
          <div className="card-header">
            <div className="card-title">
              <h4 className="">
                <b>Property Information</b>
              </h4>
            </div>
          </div>
          <div style={{ marginBottom: '-50px' }} className="card-body">
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <label className="font-weight-normal" htmlFor="owner_name_new">
                    Owner Name{' '}
                  </label>
                  <input
                    name="owner_name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Owner Name (Optional)"
                    id="owner_name_new"
                    value={management?.owner}
                    onChange={e =>
                      setManagement({
                        ...management,
                        owner: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-4">
                  <label className="font-weight-normal" htmlFor="tenant_name_new">
                    Tenant Name{' '}
                  </label>
                  <input
                    name="tenant_name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Tenant Name (Optional)"
                    id="tenant_name_new"
                    value={management?.tenant}
                    onChange={e =>
                      setManagement({
                        ...management,
                        tenant: e.target.value,
                      })
                    }
                  />
                </div>
                <div className=" col-lg-4 col-md-4 ">
                  <label className="font-weight-normal">Property Category</label>
                  <select
                    className=" mb-2 select-meter"
                    name="category"
                    id="site_category"
                    onChange={e =>
                      setManagement({
                        ...management,
                        category: e.target.value,
                      })
                    }
                  >
                    <option value="">Select a Category</option>

                    {['Rented', 'Rental'].map(val =>
                      val === management?.category ? (
                        <option key={val} selected value={val}>
                          {val}
                        </option>
                      ) : (
                        <option key={val} value={val}>{val}</option>
                      ),
                    )}
                  </select>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <label className="font-weight-normal">Start Rental Date</label>
                  <input
                    type="date"
                    className="form-control mb-2"
                    name="service_date"
                    id="rent_start_date"
                    placeholder="Start Date"
                    value={formateDate(management?.start_rent_date?.split('T')[0])}
                    onChange={e =>
                      setManagement({
                        ...management,
                        start_rent_date: e.target.value,
                      })
                    }
                  />
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                  <label className="font-weight-normal">End Rental Date</label>
                  <input
                    type="date"
                    className="form-control mb-2"
                    name="service_date"
                    id="rent_start_date"
                    placeholder="Start Date"
                    value={formateDate(management?.end_rent_date?.split('T')[0])}
                    onChange={e =>
                      setManagement({
                        ...management,
                        end_rent_date: e.target.value,
                      })
                    }
                  />
                </div>
                <label className="font-weight-normal">Description</label>
                <textarea
                  name="description"
                  value={management?.description}
                  onChange={e =>
                    setManagement({
                      ...management,
                      description: e.target.value,
                    })
                  }
                  className="form-control mb-2"
                  placeholder="Description"
                  id="description"
                />
              </div>
            </div>
          </div>
        </section>

        <div className="card-body">
          <div className="float-right row">
            <div className="row float-right">
              <button title="Add Location" className="btn primary-btn ml-2" type="submit">
                Save
              </button>
              <button
                onClick={handleCancel}
                className="btn secondary-btn ml-2"
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
