/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { createActivity, deleteActivity, resetActivity, setDescEdit, setDescription, updateActivity } from '../../../../redux/features/ticketActivity/ticketActivitySlice'

function Activities({id}) {
    const [showTextarea, setShowTextarea] = useState()
    const dispatch = useDispatch()

const {
    description,
    descEdit,
    activities
  } =useSelector(state => state.activity)

 const  comment = {description}

const handelChange = (e)=>{
    dispatch(setDescription(e.target.value))
   
}
const handelEditChange = (e)=>{
    dispatch(setDescEdit(e.target.value))
}


const handelEdit = (tId)=>{
    setShowTextarea(tId)
}
const handelDelete = (tId)=>{
    dispatch(deleteActivity(tId))
}
const handelCancel = ()=>{
    setShowTextarea()   
}
const addComment = async ()=>{
    if (description) {
        await dispatch(createActivity({ id , comment}))
        dispatch(resetActivity())
    }
}
const handelUpdate = async (tId)=>{
    if (descEdit) {
       await dispatch(updateActivity({id : tId , description: descEdit}))
        dispatch(resetActivity())
        setShowTextarea()
    }
}
  return (
    <div id="activity-tab" role="tabpanel" aria-labelledby="custom-tabs-two-activity-tab">
        <div className="card-body">
        <input type="hidden" id="comment_ticket" value="{{data.id}}" />

        <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 p-0 row" >
            <textarea
                className="form-control  col-10 animated"
                placeholder="Update your status"
                name="activity_comment"
                id="activity_comment"
                style={{height : "70px"}}
                value={description}
                onChange={handelChange}
            ></textarea>
            <div className="p-0 m-0 col-2">
                <button
                id = "comment_button"
                name = "comment_button"
                className="btn btn-primary ml-2"
                style={{height: "2.5em"}}
                onClick={addComment}
                type="button"
                >
                    <span className="button__text">Comment</span>
                </button>
            </div>
            <span className="font-danger float-left col-lg-12 col-md-12 col-sm-12" id="comment_error"></span>
        </div>
        <div id="new_activity_comment_div">
        </div>
        <div id="activity_comment_div">

                <input type="hidden" id="edit-comment-id" />
                <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 p-0 mt-3" id="activity-view-{{activity.id}}">
                    
                    {activities && activities?.length > 0 && activities.map(act =>(
                         <div key={act.id}>
                         <div className="row px-2">
                         <div style={{display: "flex" , alignItems: "center"}}>
                            <b>
                             {act.updated_by}
                             </b>  
                            <div style={{marginLeft:"15px" , fontSize: "14px"}}>{moment(act.created_at).format('YYYY-MM-DD h:mm a')}</div>
                             </div>
                             {/* <div className="gcolor ml-2">21/12/2022, 14:55</div> */}
                             
                            {showTextarea !== act.id &&  act.is_changeable  && <div className="row pl-2 ml-auto">
                                 <button type='button' title="Edit" id="activity-edit-btn-{{activity.id}}" className="history-link"
                                 onClick={()=>{
                                    handelEdit(act.id)
                                 }} >
                                     Edit
                                 </button>
                                 <button type='button'
                                 data-toggle="modal" data-target="#modal-comment"
                                 title="Delete Comment" className="pl-2 red"
                                 onClick={()=>handelDelete(act.id)}>
                                 Delete
                                 </button>
                             </div>}
                         
                         </div>
                         {showTextarea !== act.id && <div className="comment"> {act.description}</div>}
                         {showTextarea === act.id && <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 m-0 p-0 mt-0 mb-3" id="activity-edit-activity.id" >
                            <textarea
                             style={{height : "70px"}}
                                className="form-control col-10 animated"
                                placeholder="Update your status"
                                rows="1"
                                cols="50"
                                defaultValue={descEdit ? descEdit : act.description}
                                onChange={handelEditChange}
                            ></textarea>
                            <div className="row pl-2 pt-2">
                                <button type='button' title="Save" className="btn btn-primary" 
                                onClick= {()=>handelUpdate(act.id)} >
                                    Update
                                </button>

                                <button type='button'  title="Cancel" id="activity-view-btn-activity.id}}" className="btn secondary-btn ml-2" 
                                onClick={handelCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>}
                     </div>
                    ))}
                       
                                    
                                    

                                    </div>
                                   
                               
                                    </div>
                                </div>
                            </div>
  )
}

export default Activities