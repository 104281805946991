import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import checklistService  from './ticketChecklistService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Ticket Checklist
 ticketChecklists :[],
 itemResult :null,
 attachment :null,


  // Checklist fields
  description: "",
  descEdit : "",

  // Checklist Delete Popup
  checklistModalDelete:false,
  

  // success states
  isTicketChecklistGetSuccess: false,
  isTicketChecklistCreateSuccess: false,
  isTicketChecklistDeleteSuccess: false,
  isTicketChecklistUpdateSuccess: false,
  isResultUpdateSuccess: false,
 

  // loading states
  isTicketChecklistCreateLoading: false,
  isTicketChecklistGetLoading: false,
  isTicketChecklistDeleteLoading: false,
  isTicketChecklistUpdateLoading: false,
  isResultUpdateLoading: false,


  // error states
  isTicketChecklistGetError: false,
  isTicketChecklistCreateError: false,
  isTicketChecklistDeleteError: false,
  isTicketChecklistUpdateError: false,
  isResultUpdateError: false,


};

export const createChecklist = createAsyncThunk('createTickectChecklist', async (data, thunkAPI) => {
  const createChecklistRes = await smartTryCatch(checklistService.createChecklist, data, thunkAPI);
  return createChecklistRes;
});

export const getActivitiesByAuthentication = createAsyncThunk(
  'getActivitiesByAuthentication',
  async (data, thunkAPI) => {
    const getChecklists = await smartTryCatch(
      checklistService.getActivitiesByAuthentication,
      data,
      thunkAPI,
    );
    return getChecklists;
  },
);

export const getTickectChecklistById = createAsyncThunk('getTickectChecklistById', async (data, thunkAPI) => {
  const getTickectChecklist = await smartTryCatch(checklistService.getTickectChecklistById, data, thunkAPI);
  return getTickectChecklist;
});


export const deleteChecklist = createAsyncThunk('deleteTickectChecklist', async (data, thunkAPI) => {
  const deleteChecklistRes = await smartTryCatch(checklistService.deleteChecklist, data, thunkAPI);
  return deleteChecklistRes;
});
export const deleteChecklistItem = createAsyncThunk('deleteChecklistItem', async (data, thunkAPI) => {
  const deleteChecklistItemRes = await smartTryCatch(checklistService.deleteChecklistItem, data, thunkAPI);
  return deleteChecklistItemRes;
});
export const deleteBulkChecklist = createAsyncThunk('deleteBulkTickectChecklist', async (data, thunkAPI) => {
  const deleteBulkChecklistRes = await smartTryCatch(checklistService.deleteBulkChecklist, data, thunkAPI);
  return deleteBulkChecklistRes;
});

export const updateChecklist = createAsyncThunk('updateTickectChecklist', async (data, thunkAPI) => {
  const updateChecklistRes = await smartTryCatch(checklistService.updateChecklist, data, thunkAPI);
  return updateChecklistRes;
});
export const updateChecklistResult = createAsyncThunk('updateChecklistResult', async (data, thunkAPI) => {
  const updateChecklistRes = await smartTryCatch(checklistService.updateChecklistResult, data, thunkAPI);
  return updateChecklistRes;
});
export const updateChecklistItem = createAsyncThunk('updateChecklistItem', async (data, thunkAPI) => {
  const updateChecklistItemRes = await smartTryCatch(checklistService.updateChecklistItem, data, thunkAPI);
  return updateChecklistItemRes;
});
export const updateChecklistItemAttachment = createAsyncThunk('updateChecklistItemAttachment', async (data, thunkAPI) => {
  const updateChecklistItemAttachmentRes = await smartTryCatch(checklistService.updateChecklistItemAttachment, data, thunkAPI);
  return updateChecklistItemAttachmentRes;
});


export const ticketChecklistSlice = createSlice({
  name: 'ticketChecklist',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isChecklistCreateError = false;
      state.isChecklistCreateSuccess = false;
      state.isChecklistCreateLoading = false;
      state.isChecklistGetError = false;
      state.isChecklistGetSuccess = false;
      state.isChecklistGetLoading = false;
      state.isChecklistDeleteError = false;
      state.isTicketChecklistDeleteSuccess= false;
      state.isChecklistDeleteSuccess = false;
      state.isChecklistDeleteLoading = false;
      state.isChecklistUpdateError = false;
      state.isChecklistUpdateSuccess = false;
      state.isChecklistUpdateLoading = false;
      state.isResultUpdateSuccess = false;
      // state.Checklist = null;
    },
    setDescription : (state, action) => {
      state.description = action.payload;
    },
    setDescEdit : (state, action) => {
      state.descEdit = action.payload;
    },
    setTicketChecklists : (state, action) => {
      state.ticketChecklists = action.payload;
    },
    resetChecklist: (state) => {
      state.ticketChecklists = []
    },
    openChecklistModalDelete: (state) => {
      state.checklistModalDelete = true
    },
    closeChecklistModalDelete: (state) => {
      state.checklistModalDelete = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChecklist.pending, (state) => {
        state.isTicketChecklistCreateLoading = true;
        state.isTicketChecklistCreateSuccess = false;
        state.isTicketChecklistCreateError = false;
      })
      .addCase(createChecklist.fulfilled, (state, action) => {
        state.isTicketChecklistCreateLoading = false;
        state.isTicketChecklistCreateSuccess = true;
        state.isTicketChecklistCreateError = false;
        state.ticketChecklists = [...state.ticketChecklists , ...action.payload];
      })
      .addCase(createChecklist.rejected, (state) => {
        state.isTicketChecklistCreateLoading = false;
        state.isTicketChecklistCreateSuccess = false;
        state.isTicketChecklistCreateError = true;
      })
      .addCase(getTickectChecklistById.pending, (state) => {
        state.isTicketChecklistGetLoading = true;
        state.isTicketChecklistGetSuccess = false;
        state.isTicketChecklistGetError = false;
      })
      .addCase(getTickectChecklistById.fulfilled, (state, action) => {
        state.isTicketChecklistGetLoading = false;
        state.isTicketChecklistGetSuccess = true;
        state.isTicketChecklistGetError = false;
        state.ticketChecklists = action.payload;
      })
      .addCase(getTickectChecklistById.rejected, (state) => {
        state.isTicketChecklistGetLoading = false;
        state.isTicketChecklistGetSuccess = false;
        state.isTicketChecklistGetError = true;
      })
      .addCase(deleteChecklist.pending, (state) => {
        state.isTicketChecklistDeleteLoading = true;
        state.isTicketChecklistDeleteSuccess = false;
        state.isTicketChecklistDeleteError = false;
      })
      .addCase(deleteChecklist.fulfilled, (state, action) => {
        state.isTicketChecklistDeleteLoading = false;
        state.isTicketChecklistDeleteSuccess = true;
        state.isTicketChecklistDeleteError = false;
        state.ticketChecklists = 
        state.ticketChecklists.filter(item => item.id !== action.payload.id)
      })
      .addCase(deleteChecklist.rejected, (state) => {
        state.isTicketChecklistDeleteLoading = false;
        state.isTicketChecklistDeleteSuccess = false;
        state.isTicketChecklistDeleteError = true;
      })
      .addCase(deleteBulkChecklist.pending, (state) => {
        state.isTicketChecklistDeleteLoading = true;
        state.isTicketChecklistDeleteSuccess = false;
        state.isTicketChecklistDeleteError = false;
      })
      .addCase(deleteBulkChecklist.fulfilled, (state, action) => {
        state.isTicketChecklistDeleteLoading = false;
        state.isTicketChecklistDeleteSuccess = true;
        state.isTicketChecklistDeleteError = false;
        state.ticketChecklists = 
        state.ticketChecklists.filter(item => !action.payload.ids.includes(item.id))
      })
      .addCase(deleteBulkChecklist.rejected, (state) => {
        state.isTicketChecklistDeleteLoading = false;
        state.isTicketChecklistDeleteSuccess = false;
        state.isTicketChecklistDeleteError = true;
      })
      .addCase(deleteChecklistItem.pending, (state) => {
        state.isTicketChecklistDeleteLoading = true;
        state.isTicketChecklistDeleteSuccess = false;
        state.isTicketChecklistDeleteError = false;
      })
      .addCase(deleteChecklistItem.fulfilled, (state, action) => {
        const {itemId , checklistId} = action.payload
        console.log(itemId, checklistId);
        state.isTicketChecklistDeleteLoading = false;
        state.isTicketChecklistDeleteSuccess = true;
        state.isTicketChecklistDeleteError = false;
        state.ticketChecklists = state.ticketChecklists.map(item =>{
          if (item.id === checklistId) {
            const items = item.items.filter(f => f.id !== itemId)
            return {...item , items}
          }
          return item
        })
      })
      .addCase(deleteChecklistItem.rejected, (state) => {
        state.isTicketChecklistDeleteLoading = false;
        state.isTicketChecklistDeleteSuccess = false;
        state.isTicketChecklistDeleteError = true;
      })

      .addCase(updateChecklist.pending, (state) => {
        state.isTicketChecklistUpdateLoading = true;
        state.isTicketChecklistUpdateSuccess = false;
        state.isTicketChecklistUpdateError = false;
      })
      .addCase(updateChecklist.fulfilled, (state , action) => {
        state.isTicketChecklistUpdateLoading = false;
        state.isTicketChecklistUpdateSuccess = true;
        state.isTicketChecklistUpdateError = false;
        state.ticketChecklists = action.payload;
        // state.ticketChecklists = state.ticketChecklists?.map((checklist) =>
        // checklist.id === action.payload.id ? action.payload : checklist,
        // );
      }).addCase(updateChecklist.rejected, (state) => {
        state.isTicketChecklistUpdateLoading = false;
        state.isTicketChecklistUpdateSuccess = false;
        state.isTicketChecklistUpdateError = true;
      })
      .addCase(updateChecklistResult.pending, (state) => {
        state.isResultUpdateLoading = true;
        state.isResultUpdateSuccess = false;
        state.isResultUpdateError = false;
      })
      .addCase(updateChecklistResult.fulfilled, (state) => {
        state.isResultUpdateLoading = false;
        state.isResultUpdateSuccess = true;
        state.isResultUpdateError = false;
        // state.ticketChecklists = state.ticketChecklists?.map((checklist) =>
        // checklist.id === action.payload.id ? action.payload : checklist,
        // );
      }).addCase(updateChecklistResult.rejected, (state) => {
        state.isResultUpdateLoading = false;
        state.isResultUpdateSuccess = false;
        state.isResultUpdateError = true;
      })
      .addCase(updateChecklistItem.fulfilled, (state, action) => {
        state.isResultUpdateLoading = false;
        state.isResultUpdateSuccess = true;
        state.isResultUpdateError = false;
        state.itemResult = action.payload;
      })

      .addCase(updateChecklistItemAttachment.fulfilled, (state, action) => {
        state.isResultUpdateLoading = false;
        state.isResultUpdateSuccess = true;
        state.isResultUpdateError = false;
        state.itemResult = action.payload;
      })
},
});

export const {
  setTicketChecklists,
  setDescription,
  resetChecklist,
  reset,
  setDescEdit,
  openChecklistModalDelete,
  closeChecklistModalDelete
} = ticketChecklistSlice.actions;

export default ticketChecklistSlice.reducer;
