/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { setFilterStatus, setStaff, setWOFilterCategory, setWOFilterSite, setWOFilterSublocation, setWOFiltertype } from '../../redux/features/workOrder/workOrderSlice';
import { SelectLocationFilter, SelectStaff, SelectSublocationFilter, SelectTypesAndCategories } from '../../components/select/select';

const WOFilter = () => {
  const dispatch = useDispatch();
  const [locationsData, setLocations] = useState([]);
  const { locations } = useSelector((state) => state.location);
  const { workOrderCategories } = useSelector((state) => state.workOrderCategory);
  const { workOrderTypes } = useSelector((state) => state.workOrderType);
  const sublocations = useSelector((state) => state.location?.sublocations?.results);
  const { teams } = useSelector((state) => state.teams);
  const statusData = [
    { id: 1, name: 'Open' },
    { id: 2, name: 'In-Progress' },
    { id: 3, name: 'On-Hold' },
    { id: 4, name: 'Completed' },
  ]
  const
    {
      site,
      sublocation,
      filterCategory,
      type,
      staff,
      filterStatus
    } = useSelector(state => state.workOrder)



  const resetState = () => {
    dispatch(setWOFilterSite(null));
    dispatch(setWOFilterSublocation(null));
    dispatch(setWOFilterCategory(null));
    dispatch(setWOFiltertype(null));
    dispatch(setFilterStatus(null));
    dispatch(setStaff(null));
  }

  const handleEraserFilterClick = () => {
    setLocations([]);
    // reset the state and store
    resetState()
  };

  useEffect(() => {
    setLocations(sublocations || [])
  }, [sublocations])

  return (
    <div className="filters-wrapper">
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Location </label>
        <SelectLocationFilter locations={locations} site={site} setFilterSite={setWOFilterSite}
          setFilterSublocation={setWOFilterSublocation} setLocations={setLocations} />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Sublocation</label>
        <SelectSublocationFilter sublocationsData={locationsData} sublocation={sublocation} setFilterSublocation={setWOFilterSublocation} site={site} />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Category</label>
        <SelectTypesAndCategories dataArray={workOrderCategories} stateValue={filterCategory} setState={setWOFilterCategory} />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Sub Category</label>
        <SelectTypesAndCategories dataArray={workOrderTypes} stateValue={type} setState={setWOFiltertype} />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Status</label>
        <SelectTypesAndCategories dataArray={statusData} stateValue={filterStatus} setState={setFilterStatus} />
      </div>
      <div className="filter-wrapper">
        <label style={{ color: '#d63c3c', fontWeight: 500 }}>Staff</label>
        <SelectStaff staff={staff} setStaff={setStaff} teams={teams?.results} />
      </div>
      <div className="erase-wrapper">
        <div className="erase-btn">
          <Button onClick={handleEraserFilterClick} title="Erase Filter" variant="outline-danger">
            <FontAwesomeIcon style={{ padding: '2px' }} icon={faEraser} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WOFilter;
