import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './editCategory.scss';
import BusinessHoursTab from './businessHours/businessHours';
import SLATab from './SLA/SLA';

export default function Settings () {
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('settingsTab')) || 'generalTab');

  useEffect(() => {
    localStorage.setItem('settingsTab', JSON.stringify(key));
  }, [key]);

  return (
    <div className=" ml-2 title">
      <div className="float left">
        <h3 className="px-2">
          <b>Settings</b>
        </h3>
      </div>
      <div>
        <Tabs
          defaultActiveKey={key}
          id="uncontrolled-tab-example"
          className="nav nav-tabs custom_tabs"
          onSelect={k => setKey(k)}
        >
          <Tab eventKey="generalTab" title="General">
            <BusinessHoursTab />
            <SLATab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
