/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import WorkOrderSummary from './WorkOrderSummary';
import icons from '../../../utils/icon';
import WoOrderPieChart from './WoOrderPieChart';
import OverallProgress from './OverallProgress';
import WOPMTrendChart from './WOPMTrendChart';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsForDashboard } from '../../../redux/features/Analytics/analyticsSlice';
import Loader from '../../loader/loader';

function AnalyticMainPage ({pdfReport}) {
  const dispatch = useDispatch();
  const {
    isAnalyticsLoading,
    analytics,
    dateWord,
    custom_start,
    custom_end,
    filter,
    site,
    location,
    staff,
    categoryFilter,
    typeFilter,
    customDate,
  } = useSelector(state => state.analytics);

  const [intervals, setIntervals] = useState('day');
  const [workCategory, setWorkCategory] = useState([0, 0]);
  const [totalWorkOrder, setTotalWorkOrder] = useState(0);
  const [summaryData, setSummaryData] = useState({});
  const [overallData,setOverallData] = useState({});
  const [comparingData,setComparingData] = useState({});
  const [openWork, setOpenWork] = useState([]);
  const [closeWork, setCloseWork] = useState([]);
  const trendWorkData = {
    open: [],
    close: [],
    workDates: [],
  };
  useEffect(() => {
    dispatch(
      getAnalyticsForDashboard({
        filter:
          filter?.value === 'custom' ? customDate : filter?.value ? filter?.value : 'last7days',
        site: site?.value || null,
        location: location?.value || null,
        staff: staff?.value || null,
        type: typeFilter?.label === 'All' || !typeFilter?.label ? null : typeFilter?.label,
        category:
          categoryFilter?.label === 'All' || !categoryFilter?.label ? null : categoryFilter?.label,
      }),
    );
  }, []);
  // filter, custom_start, custom_end, site, location, staff, typeFilter, categoryFilter


  useEffect(() => {
    setWorkCategory([
      parseFloat(analytics?.tickets_based_on_category?.preventive),
      parseFloat(analytics?.tickets_based_on_category?.corrective, 10),
    ]);
    setOverallData(analytics?.overall_progress)
    setTotalWorkOrder(analytics?.tickets_count?.total_tickets || 0);
    setSummaryData(analytics?.ticket_count_by_category);
    setComparingData(analytics?.comparing_data)
    if (filter === null || filter?.value === 'last15days' || dateWord === 'custom') {
      setIntervals('day');
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);
    } else if (filter?.value === 'last30days') {
      setOpenWork(analytics?.tickets_by_status?.open_day);
      setCloseWork(analytics?.tickets_by_status?.close_day);

      setIntervals('Day');
      // if (intervals === 'day') {
      //   setOpenWork(analytics?.tickets_by_status?.open_day);
      //   setCloseWork(analytics?.tickets_by_status?.close_day);
      // } else {
      //   setIntervals('week');
      //   setOpenWork(analytics?.tickets_by_status?.open_week);
      //   setCloseWork(analytics?.tickets_by_status?.close_week);
      // }
    } else if (filter?.value === 'last365days') {
      setOpenWork(analytics?.tickets_by_status?.open_month);
      setCloseWork(analytics?.tickets_by_status?.close_month);
      setIntervals('month');
      // if (intervals === 'month') {
      //   setOpenWork(analytics?.tickets_by_status?.open_month);
      //   setCloseWork(analytics?.tickets_by_status?.close_month);
      // } else {
      //   setIntervals('quarter');
      //   setOpenWork(analytics?.tickets_by_status?.open_quarter);
      //   setCloseWork(analytics?.tickets_by_status?.close_quarter);
      // }
    }
  }, [analytics, intervals, filter]);
  function handleWork () {
    openWork?.forEach(work => {
      trendWorkData.workDates.push(work[1].split('T')[0]);
      trendWorkData.open.push(work[0]);
    });
    closeWork?.forEach(work => {
      trendWorkData.close.push(work[0]);
    });
  }
  handleWork();

  return (isAnalyticsLoading ? <Loader /> :
    <div className="row col-lg-12 col-12 mx-auto mt-3">
      <div className="col-12">
        <p
          style={{
            fontFamily: 'Raleway',
            fontSize: '22px',
            fontWeight: '600',
            lineHeight: '25.83px',
            letterSpacing: '0.01em',
            textAlign: 'left',
          }}
        >
          Work Order Summary
        </p>
      </div>
      <div
        style={{
          border: '1px solid',
          radius: '10px',
          padding: '20px 0px 20px 0px',
          borderColor: '#E7E7E7',
          borderRadius: '10px',
          boxShadow: '0px 2px 1px 0px #1018280D',
          marginLeft: '3px',
        }}
        className="row col-lg-12 col-12"
      >
        <WorkOrderSummary
          key={1}
          icon={icons.total}
          color="#8B5CF4"
          title="Total"
          value={summaryData?.total_workorder}
          percentage={comparingData?.total_workorder <= 0 ? `${comparingData?.total_workorder}%` : `+${comparingData?.total_workorder}%`}
          perBackColor= {comparingData?.total_workorder === 0 ? "#fd941b2a" : comparingData?.total_workorder > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.total_workorder === 0 ? "#FD931B" : comparingData?.total_workorder > 0 ? "#6DA544":"#D9314A"}
          perIcon ={{ icon: comparingData?.total_workorder === 0 ? faArrowRight :  comparingData?.total_workorder >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />

        <WorkOrderSummary
          key={2}
          icon={icons.completed}
          color="#76B559"
          title="Completed"
          value={summaryData?.total_completed_workorder}
          percentage={comparingData?.completed_workorder <= 0 ? `${comparingData?.completed_workorder}%` : `+${comparingData?.completed_workorder}%`}
          perBackColor= {comparingData?.completed_workorder === 0 ? "#fd941b2a" : comparingData?.completed_workorder > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.completed_workorder === 0 ? "#FD931B" : comparingData?.completed_workorder > 0 ? "#6DA544":"#D9314A"}
          perIcon ={{ icon: comparingData?.completed_workorder === 0 ? faArrowRight :  comparingData?.completed_workorder >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />
        <WorkOrderSummary
          key={3}
          icon={icons.open}
          color="#439EE0"
          title="Open"
          value={summaryData?.total_open_workorder}
          percentage={comparingData?.open_workorder <= 0? `${comparingData?.open_workorder}%` :`+${comparingData?.open_workorder}%`}
          perBackColor= {comparingData?.open_workorder === 0 ? "#fd941b2a" : comparingData?.open_workorder > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.open_workorder === 0 ? "#FD931B" : comparingData?.open_workorder > 0 ? "#6DA544":"#D9314A"}
          perIcon ={{ icon: comparingData?.open_workorder === 0 ? faArrowRight :  comparingData?.open_workorder >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />

        <WorkOrderSummary
          key={4}
          icon={icons.hold}
          color="#D63C3C"
          title="On Hold"
          value={summaryData?.total_on_hold_workorder}
          percentage={comparingData?.on_hold_workorder <= 0? `${comparingData?.on_hold_workorder}%` : `+${comparingData?.on_hold_workorder}%`}
          perBackColor= {comparingData?.on_hold_workorder === 0 ? "#fd941b2a" : comparingData?.on_hold_workorder > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.on_hold_workorder === 0 ? "#FD931B" : comparingData?.on_hold_workorder > 0 ? "#6DA544":"#D9314A"}
          perIcon={{ icon: comparingData?.on_hold_workorder === 0 ? faArrowRight :  comparingData?.on_hold_workorder >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />

        <WorkOrderSummary
          key={5}
          icon={icons.progress}
          color="#DD6B17"
          title="In Progress"
          value={summaryData?.total_on_progress_workorder}
          percentage={comparingData?.on_progress_workorder <= 0? `${comparingData?.on_progress_workorder}%` :`+${comparingData?.on_progress_workorder}%`}
          perBackColor= {comparingData?.on_progress_workorder === 0 ? "#fd941b2a" : comparingData?.on_progress_workorder > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.on_progress_workorder === 0 ? "#FD931B" : comparingData?.on_progress_workorder > 0 ? "#6DA544":"#D9314A"}
          perIcon={{ icon: comparingData?.on_progress_workorder === 0 ? faArrowRight :  comparingData?.on_progress_workorder >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
        />
      </div>
      {/* Preventive maintenance  */}
      <div className="col-12 mt-3">
        <p
          style={{
            fontFamily: 'Raleway',
            fontSize: '22px',
            fontWeight: '600',
            lineHeight: '25.83px',
            letterSpacing: '0.01em',
            textAlign: 'left',
          }}
        >
          Preventive Maintenance Summary
        </p>
      </div>
      <div
        style={{
          border: '1px solid',
          radius: '10px',
          padding: '20px 0px 20px 0px',
          borderColor: '#E7E7E7',
          borderRadius: '10px',
          boxShadow: '0px 2px 1px 0px #1018280D',
          marginLeft: '3px',
        }}
        className="row col-lg-12 col-12"
      >
        <WorkOrderSummary
          key={1}
          icon={icons.total}
          color="#8B5CF4"
          title="Total"
          value={summaryData?.total_pm}
          percentage={comparingData?.total_pm <= 0? `${comparingData?.total_pm}%` :`+${comparingData?.total_pm}%`}
          perBackColor= {comparingData?.total_pm === 0 ? "#fd941b2a" : comparingData?.total_pm > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.total_pm === 0 ? "#FD931B" : comparingData?.total_pm > 0 ? "#6DA544":"#D9314A"}

          perIcon ={{ icon: comparingData?.total_pm === 0 ? faArrowRight :  comparingData?.total_pm >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />

        <WorkOrderSummary
          key={2}
          icon={icons.completed}
          color="#76B559"
          title="Completed"
          value={summaryData?.total_completed_pm}
          percentage={comparingData?.completed_pm <= 0? `${comparingData?.completed_pm}%` :`+${comparingData?.completed_pm}%`}
          perBackColor= {comparingData?.completed_pm === 0 ? "#fd941b2a" : comparingData?.completed_pm > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.completed_pm === 0 ? "#FD931B" : comparingData?.completed_pm > 0 ? "#6DA544":"#D9314A"}
          perIcon ={{ icon: comparingData?.completed_pm === 0 ? faArrowRight :  comparingData?.completed_pm >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />
        <WorkOrderSummary
          key={3}
          icon={icons.open}
          color="#439EE0"
          title="Open"
          value={summaryData?.total_open_pm}
          percentage={comparingData?.open_pm <= 0? `${comparingData?.open_pm}%`:`+${comparingData?.open_pm}%`}
          perBackColor= {comparingData?.open_pm === 0 ? "#fd941b2a" : comparingData?.open_pm > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.open_pm === 0 ? "#FD931B" : comparingData?.open_pm > 0 ? "#6DA544":"#D9314A"}
          perIcon ={{ icon: comparingData?.open_pm === 0 ? faArrowRight :  comparingData?.open_pm >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />

        <WorkOrderSummary
          key={4}
          icon={icons.hold}
          color="#D63C3C"
          title="On Hold"
          value={summaryData?.total_on_hold_pm}
          percentage={comparingData?.on_hold_pm <= 0? `${comparingData?.on_hold_pm}%` :`+${comparingData?.on_hold_pm}%`}
          perBackColor= {comparingData?.on_hold_pm === 0 ? "#fd941b2a" : comparingData?.on_hold_pm > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.on_hold_pm === 0 ? "#FD931B" : comparingData?.on_hold_pm > 0 ? "#6DA544":"#D9314A"}

          perIcon={{ icon: comparingData?.on_hold_pm === 0 ? faArrowRight :  comparingData?.on_hold_pm >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
          border="1px solid #E7E7E7"
        />

        <WorkOrderSummary
          key={5}
          icon={icons.progress}
          color="#DD6B17"
          title="In Progress"
          value={summaryData?.total_in_progress_pm}
          percentage={comparingData?.in_progress_pm <= 0? `${comparingData?.in_progress_pm}%`:`+${comparingData?.in_progress_pm}%`}
          perBackColor= {comparingData?.in_progress_pm === 0 ? "#fd941b2a" : comparingData?.in_progress_pm > 0 ? "#6DA5441A": "#D9314A1A"}
          perColor={comparingData?.in_progress_pm === 0 ? "#FD931B" : comparingData?.in_progress_pm > 0 ? "#6DA544":"#D9314A"}
          perIcon={{ icon: comparingData?.in_progress_pm === 0 ? faArrowRight :  comparingData?.in_progress_pm >  0 ? faArrowUp  :  faArrowDown  }}
          // date="(25 Jan - 25 Mar)"
        />
      </div>
    <div
        className="row col-lg-12 col-12"
        style={{
          marginTop: '25px',
          breakInside: 'avoid'
        }}
      >
        <div className="col-md-5 col-sm-6">
          <p
            style={{
              fontFamily: 'Raleway',
              fontSize: '22px',
              fontWeight: 600,
              lineHeight: '25.83px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            WO vs PM
          </p>
          <div
            style={{
              border: '1px solid #EAEBEC',
              borderRadius: '11px',
              padding: '23px',
              boxShadow: '0px 2px 1px 0px #1018280D',
              height:"333px"
            }}
          >
            <WoOrderPieChart data={workCategory} pdfReport={pdfReport} />
          </div>
        </div>
        <div className="col-md-7 col-sm-6">
          <p
            style={{
              fontFamily: 'Raleway',
              fontSize: '22px',
              fontWeight: 600,
              lineHeight: '25.83px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Overall Progress
          </p>
          <div
            style={{
              border: '1px solid #EAEBEC',
              borderRadius: '11px',
              padding: '23px',
              boxShadow: '0px 2px 1px 0px #1018280D',
              paddingTop: '60px',
              height:"333px"
            }}
          >
            <OverallProgress type={'Work Orders'} percent={overallData?.wo_completion} color={'#439EE0'} />
            <OverallProgress type={'PM'} percent={overallData?.pm_completion} color={'#DD6B17'} />
            <OverallProgress type={'Overall'} percent={overallData?.overall} color={'#76B559'} />
          </div>
        </div>
        {!pdfReport && <div className="col-12 mt-5 mb-3">
          <p
            style={{
              fontFamily: 'Raleway',
              fontSize: '22px',
              fontWeight: 600,
              lineHeight: '25.83px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Trend of Work Orders
          </p>
          <div
            style={{
              border: '1px solid #EAEBEC',
              borderRadius: '11px',
              padding: '23px',
              boxShadow: '0px 2px 1px 0px #1018280D',
              paddingTop: '35px',
            }}
          >
            <WOPMTrendChart trendWorkData={trendWorkData} intervals={intervals} />
          </div>
        </div>}
      </div>
    </div>
  );
}

export default AnalyticMainPage;
