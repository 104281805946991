/* eslint-disable no-unused-vars */


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import searchIcon from "../../assets/img/searchIcon.png"
import {
  // setChecklist,
  openChecklistModalCreate,
  openChecklistModalUpdate,
  reset,
  getPagination,
  setSearchValue,
  getChecklistById,
  setCurrentPage,
  setRowPerPage,
} from '../../redux/features/checklist/checklistSlice';
// import Table from '../../components/crudTable/crudTable';
import SearchHeader from '../../components/searchHeader/searchHeader';
import ChecklistCreateModal from '../../components/checklists/checklistCreateModal';
import ChecklistUpdateModal from '../../components/checklists/checklistUpdateModal';
import Pagination from '../../components/pagination/pagination';
import Loader from '../../components/loader/loader';
import ChecklistTable from '../../components/ChecklistTable/ChecklistTable';

export default function Checklists () {
  const dispatch = useDispatch();
  const {
    checklists,
    searchValue,
    // success
    isCreateChecklistSuccess,
    isDeleteChecklistSuccess,
    isUpdateChecklistSuccess,

    // loading
    isCreateChecklistLoading,
    isDeleteChecklistLoading,
    isGetChecklistLoading,
    isUpdateChecklistLoading,

    // error
    isCreateChecklistError,
    isDeleteChecklistError,
    isUpdateChecklistError,
    currentPage, 
    rowPerPage
  } = useSelector(state => state.checklists);

  const checklistDataCount = useSelector(state => state.checklists?.checklists?.count);

  const [isLoading, setIsLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowPerPage, setRowPerPage] = useState(10);

  // useEffect(() => {
  //   if (searchValue !== '') {
  //     setCurrentPage(1);
  //   }
  // }, [searchValue]);

  useEffect(() => {
    const getDataRows = async () => {
      setIsLoading(true);
      await dispatch(getPagination({ rowPerPage, currentPage, searchValue }));
      setIsLoading(false);
    };

    getDataRows();
  }, [rowPerPage, currentPage, searchValue]);

  useEffect(() => {
    dispatch(reset());
  }, [
    isCreateChecklistSuccess,
    isDeleteChecklistSuccess,
    isUpdateChecklistSuccess,

    // loading
    isCreateChecklistLoading,
    isDeleteChecklistLoading,
    isGetChecklistLoading,
    isUpdateChecklistLoading,

    // error
    isCreateChecklistError,
    isDeleteChecklistError,
    isUpdateChecklistError,
  ]);

  const [data, setData] = useState(checklists.results || []);
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [checkedDate, setDate] = useState(true);
  const [List, setList] = useState(true);
  const [checkedTask, setTask] = useState(true);
  const [columns, setCol] = useState([
    {
      Header: 'Checklist',
      accessor: 'title',
    },
    {
      Header: 'Create Date',
      accessor: 'created_at',
    },
    {
      Header: 'Tasks',
      accessor: 'items_count',
    },
  ]);
  const [count] = useState(0);
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const handleOpenAdd = () => {
    dispatch(openChecklistModalCreate());
    setOpenAdd(true);
  };

  const handleOpen = () => {
    setOpenUpdate(true);
    // dispatch(setChecklist(row));
  };


  const getChecklistRowById = (id)=>{
  // dispatch(setChecklist(row));
  dispatch(getChecklistById(id))
  dispatch(openChecklistModalUpdate());
  handleOpen();
}

  useEffect(() => {
    setData(checklists.results || []);
  }, [checklists]);

  const handleLists = () => {
    if (List === true) {
      const ids = columns.filter(item => item.accessor !== 'title');

      setCol(ids);
    } else if (columns.length !== 3 && List === false) {
      setCol([
        ...columns,
        {
          Header: 'Checklist',
          accessor: 'title',
        },
      ]);
    }
    setList(!List);
  };
  const handleTasks = () => {
    if (checkedTask === true) {
      const ids = columns.filter(item => item.accessor !== 'items_count');

      setCol(ids);
    } else if (columns.length !== 3 && checkedTask === false) {
      setCol([
        ...columns,
        {
          Header: 'Tasks',
          accessor: 'items_count',
        },
      ]);
    }
    setTask(!checkedTask);
  };
  const handleDates = () => {
    if (checkedDate === true) {
      const ids = columns.filter(item => item.accessor !== 'created_at');
      columns.filter(item => item.accessor !== 'created_at');
      setCol(ids);
    } else if (columns.length !== 3 && checkedDate === false) {
      setCol([
        ...columns,
        {
          Header: 'Create Date',
          accessor: 'created_at',
        },
      ]);
    }
    setDate(!checkedDate);
  };

  function displayToast () {
    if (isCreateChecklistSuccess) {
      toast.success('Checklist created successfully', {
        toastId: 'checklistCreateSuccess',
      });
    } else if (isDeleteChecklistSuccess) {
      toast.success('Checklist deleted successfully', {
        toastId: 'checklistDeleteSuccess',
      });
    } else if (isUpdateChecklistSuccess) {
      toast.success('Checklist Updated successfully', {
        toastId: 'checklistupdateSuccess',
      });
    } else if (isCreateChecklistError) {
      toast.error('Something went wrong with creating the checklist', {
        toastId: 'checklistCreateError',
      });
    } else if (isDeleteChecklistError) {
      toast.error('Something went wrong with deleteing the checklist', {
        toastId: 'checklistDeleteError',
      });
    } else if (isUpdateChecklistError) {
      toast.error('Something went wrong with Updating the checklist', {
        toastId: 'checklistupdateError',
      });
    }
  }
  return "" ? (
    <Loader />
  ) : (
    <div className='checklist-page'>
      {displayToast()}
      <SearchHeader
        pageName="Checklist templates"
        pagePath="/checklists"
        // includeFilterButton
        checkedDate={checkedDate}
        checkedList={List}
        checkedTask={checkedTask}
        handleList={handleLists}
        handleDate={handleDates}
        handleTask={handleTasks}
        type="checklist"
      >
        <div className="row searc-head">
          <div className='checklist-main-search'>
          <img src={searchIcon} alt="search" />
          <input
            value={searchValue}
            placeholder="Search checklist"
            className="rgfloat float-right "
            onChange={e => dispatch(setSearchValue(e.target.value))}
          />
        </div>
        </div>
      </SearchHeader>
      {isLoading ? (
        <Loader />
      ) : (
        <>
        <ChecklistTable getChecklistRowById={getChecklistRowById}/>
        <ChecklistUpdateModal show={openUpdate} close={handleCloseUpdate}  />
        {/* // <Table
        //   isLoading={isLoading}
        //   columns={columns}
        //   data={data}
        //   pageNum={count}
        //   onRowClick={row => {
        //     // dispatch(setChecklist(row));
        //     dispatch(getChecklistById(row?.id))
        //     dispatch(openChecklistModalUpdate());
        //     handleOpen();
        //   }}
        // >
        //   <ChecklistCreateModal show={openAdd} close={handleCloseAdd} checklistData={data} />
        // </Table> */}
        <Pagination
          totalRows={checklistDataCount}
          rowPerPage={rowPerPage}
          pageChangeHandler={setCurrentPage}
          changeRowsPerPage={setRowPerPage}
          currentPage={currentPage}
          checklist
        />
        </>
      )}
    </div>
  );
}
