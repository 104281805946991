/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function WOPMTrendChart({ trendWorkData, intervals }) {
  const formattedCategories = trendWorkData?.workDates.map(dateString => {
    const date = parseISO(dateString);
    return format(date, 'eeee, MMM, d, yyyy');
  });

  const series = [
    {
      name: 'Work Order Open',
      data: trendWorkData?.open,
    },
    {
      name: 'Work Order Close',
      data: trendWorkData?.close,
      stroke: {
        width: 1,
      },
    }
  ];

  const options = {
    colors: ['#8B5CF4', '#A3A3A3'],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      x: {
        formatter: function (value, { indexSeries, seriesIndex, dataPointIndex, w }) {
          return formattedCategories[dataPointIndex];
        },
      },
      y: {
        formatter: function (value) {
          return value;
        },
      },
      fixed: {
        enabled: false,
        offsetX: -20,
        offsetY: -10,
      },
    },
    stroke: {
      width: 1.5,
      curve: 'straight',
    },
    // title: {
    //   text: "Trend of Work Orders",
    //   align: "left",
    //   style: {
    //     fontFamily: "Raleway",
    //     fontWeight: 600,
    //     fontSize: "18px",
    //     lineHeight: "21.13px",
    //   }
    // },
    markers: {
      size: 0,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: trendWorkData?.workDates,
      tickAmount: 6,
      labels: {
        formatter: function (value) {
          const date = new Date(value);
          const month = date.toLocaleString('default', { month: 'short' });
          const day = date.getDate();
          const formattedDate = `${month}-${day}`;
          return formattedDate;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: 'Number of Work Orders',
        style: {
          fontFamily: "Raleway",
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#19191966"
        }
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      // offsetY: -5,
      offsetX: -5,
      fontFamily: "Raleway",
      fontWeight: 500,
      fontSize: "14px"
    },
  };

  return (
    <div id="trendChart">
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
}

export default WOPMTrendChart;

