import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import maintenanceService from './maintenanceService';
import { smartTryCatch } from '../../../utils';
import workOrderService from '../workOrder/workOrderService';

const initState = {
  // Assets
  maintenances: [],
  maintenance: {},
  TicketActivity :[],

  tab : "maintenance-tab",
  searchValue: "",
  // modals
  maintenanceModalDelete: false,
  maintenanceModalUpdate: false,
  maintenanceModalCreate: false,

  // Maintenance fields
  maintenanceId : null,
  ticketType : "",
  title: "",
  description: "",
  priority: "",
  category: "",
  maintenanceType: "",
  signature: "",
  start_date : "",
  due_finish : "",
  end_date: "",
  completed_date : "",
  startDate : "",
  isShowCost : false,
  assigned_to: "",
  assigned: "",
  asset: "",
  location: "",
  time : "",
  status : "",
  frequency_number: "",
  frequency:"",
  attachments: [],
  files: [],  
  filterSite: null,  
  filterSublocation: null,  
  filterCategory: null,  
  filtertype: null,  
  staff: null,
  filterStatus: null,

  // pagination
  currentPage: 1,
  rowPerPage: 10,


  // success states
  isMaintenanceGetSuccess: false,
  isMaintenanceCreateSuccess: false,
  isMaintenanceDeleteSuccess: false,
  isMaintenanceUpdateSuccess: false,
  isDeleteTicketAttachmentSuccess: false,

  // loading states
  isMaintenanceCreateLoading: false,
  isMaintenanceGetLoading: false,
  isMaintenancesGetLoading: false,
  isMaintenanceDeleteLoading: false,
  isMaintenanceUpdateLoading: false,


  // error states
  isMaintenanceGetError: false,
  isMaintenanceCreateError: false,
  isMaintenanceDeleteError: false,
  isMaintenanceUpdateError: false,


};

export const createMaintenance = createAsyncThunk('createMaintenance', async (data, thunkAPI) => {
  const createMaintenanceRes = await smartTryCatch(maintenanceService.createMaintenance, data, thunkAPI);
  return createMaintenanceRes;
});

export const getMaintenancesByAuthentication = createAsyncThunk(
  'getMaintenancesByAuthentication',
  async (data, thunkAPI) => {
    const getMaintenances = await smartTryCatch(
      maintenanceService.getMaintenancesByAuthentication,
      data,
      thunkAPI,
    );
    return getMaintenances;
  },
);
export const getPagination = createAsyncThunk('getPaginationForPM', async (data, thunkAPI) => {
  const getPM = await smartTryCatch(maintenanceService.getPagination, data, thunkAPI);
  return getPM;
});

export const getMaintenanceById = createAsyncThunk('getMaintenanceById', async (data, thunkAPI) => {
  const getMaintenance = await smartTryCatch(maintenanceService.getMaintenanceById, data, thunkAPI);
  return getMaintenance;
});


export const deleteMaintenance = createAsyncThunk('deleteMaintenance', async (data, thunkAPI) => {
  const deleteMaintenanceRes = await smartTryCatch(maintenanceService.deleteMaintenance, data, thunkAPI);
  return deleteMaintenanceRes;
});
export const updateMaintenance = createAsyncThunk('updateMaintenance', async (data, thunkAPI) => {
  const updateMaintenanceRes = await smartTryCatch(maintenanceService.updateMaintenance, data, thunkAPI);
  return updateMaintenanceRes;
});

export const addAttachmentTicket = createAsyncThunk('addAttachment', async (data, thunkAPI) => {
  const addAttachmentRes = await smartTryCatch(workOrderService.addAttachment, data, thunkAPI);
  return addAttachmentRes;
});
export const editAttachmentTicket = createAsyncThunk('editAttachment', async (data, thunkAPI) => {
  const EditAttachmentRes = await smartTryCatch(workOrderService.editAttachment, data, thunkAPI);
  return EditAttachmentRes;
});
export const deleteAttachmentTicket = createAsyncThunk('deleteAttachment', async (data, thunkAPI) => {
  const deleteAttachmentRes = await smartTryCatch(workOrderService.deleteAttachment, data, thunkAPI);
  return deleteAttachmentRes;
});

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isMaintenanceCreateError = false;
      state.isMaintenanceCreateSuccess = false;
      state.isMaintenanceCreateLoading = false;
      state.isMaintenanceGetError = false;
      state.isMaintenanceGetSuccess = false;
      state.isMaintenanceGetLoading = false;
      state.isMaintenanceDeleteError = false;
      state.isMaintenanceDeleteSuccess = false;
      state.isMaintenanceDeleteLoading = false;
      state.isMaintenanceUpdateError = false;
      state.isMaintenanceUpdateSuccess = false;
      state.isMaintenanceUpdateLoading = false;
      state.isMaintenancesGetLoading = false;
      state.isDeleteTicketAttachmentSuccess = false;
      // state.maintenance = null;
    },
    openMaintenanceModalCreate: (state) => {
      state.maintenanceModalCreate = true;
    },
    closeMaintenanceModalCreate: (state) => {
      state.maintenanceModalCreate = false;
      state.attachments = []
    },
    openMaintenanceModalUpdate: (state) => {
      state.maintenanceModalUpdate = true;
    },
    closeMaintenanceModalUpdate: (state) => {
      state.maintenanceModalUpdate = false;
      state.files = []
      state.attachments = []
    },
    openMaintenanceModalDelete: (state) => {
      state.maintenanceModalDelete = true;
    },
    closeMaintenanceModalDelete: (state) => {
      state.maintenanceModalDelete = false;
    },
    setMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    setMaintenanceId: (state, action) => {
      state.maintenanceId = action.payload;
    },
    setTab : (state , action) => {
      state.tab = action.payload
    },
    setTicketType : (state, action) => {
      state.ticketType = action.payload;
    },

    setTitle : (state, action) => {
      state.title = action.payload;
    },
    setDescription : (state, action) => {
      state.description = action.payload;
    },
    setPriority : (state, action) => {
      state.priority = action.payload;
    },
    setCategory : (state, action) => {
      state.category = action.payload;
    },
    setMaintenaceType : (state, action) => {
      state.maintenanceType = action.payload;
    },
    setSignature : (state, action) => {
      state.signature = action.payload;
    },
    setEndDate : (state, action) => {
      state.end_date = action.payload;
    },
    setStartedDate : (state, action) => {
      state.start_date = action.payload;
    },
    setFrequencyNumber : (state, action) => {
      state.frequency_number = action.payload;
    },
    setFrequency : (state, action) => {
      state.frequency = action.payload;
    },
    setAssignedTo : (state, action) => {
      state.assigned_to = action.payload;
    },
    setAssigned : (state, action) => {
      state.assigned = action.payload
    },
    setLocation : (state, action) => {
      state.location = Number(action.payload);
    },
    setAsset : (state, action) => {
      state.asset = Number(action.payload);
    },
    setTime : (state, action) => {
      state.time = action.payload;
    },
    setStatus : (state, action) => {
      state.status = action.payload;
    },
    setDueFinish : (state, action) => {
      state.due_finish= action.payload;
    },
    setStartDate : (state, action) => {
      state.startDate = action.payload;
    },
    setCompletedDate : (state, action) => {
      state.completed_date = action.payload;
    },
    setIsShowCost : (state, action) => {
      state.isShowCost = action.payload;
    },
    setSearchValue : (state, action) => {
      state.searchValue = action.payload;
    },
    addAttachment: (state, action) => {
      state.attachments.push(...action.payload);
    },
    setFiles: (state, action) => {
      state.files = [...state.files, ...action.payload];
    },
    getAttachment: (state, action) => {
      state.attachments= action.payload;
    },
    
    setPMFilterCategory: (state, action) => {
      state.filterCategory= action.payload;
    },
    setPMFiltertype: (state, action) => {
      state.filtertype= action.payload;
    },
    setPMFilterSite: (state, action) => {
      state.filterSite= action.payload;
    },
    setPMFilterSublocation: (state, action) => {
      state.filterSublocation= action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },

    resetMaintenance: (state) => {
      state.maintenanceId = null
      state.ticketType = ""
      state.title= ""
      state.description= ""
      state.priority= ""
      state.category= ""
      state.maintenanceType= ""
      state.signature= ""
      state.end_date= ""
      state.assigned_to= ""
      state.assigned = ""
      state.asset= ""
      state.location= ""
      state.time = ""
      state.status = ""
      state.frequency_number = ""
      state.frequency = ""
      state.due_finish = ""
      state.start_date = ""
      state.end_date = ""
      state.completed_date = ""
      state.attachments = []
      state.files = []
      state.isShowCost= false
      state.maintenanceModalDelete= false
      state.staff= null
      state.filterStatus= null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMaintenance.pending, (state) => {
        state.isMaintenanceCreateLoading = true;
        state.isMaintenanceCreateSuccess = false;
        state.isMaintenanceCreateError = false;
      })
      .addCase(createMaintenance.fulfilled, (state, action) => {
        state.isMaintenanceCreateLoading = false;
        state.isMaintenanceCreateSuccess = true;
        state.isMaintenanceCreateError = false;
        state.maintenances.results= [...action.payload , ...state.maintenances.results];
      })
      .addCase(createMaintenance.rejected, (state) => {
        state.isMaintenanceCreateLoading = false;
        state.isMaintenanceCreateSuccess = false;
        state.isMaintenanceCreateError = true;
      })
      .addCase(getMaintenancesByAuthentication.pending, (state) => {
        state.isMaintenancesGetLoading = true;
        state.isMaintenanceGetSuccess = false;
        state.isMaintenanceGetError = false;
      })
      .addCase(getMaintenancesByAuthentication.fulfilled, (state, action) => {
        state.isMaintenancesGetLoading = false;
        state.isMaintenanceGetSuccess = true;
        state.isMaintenanceGetError = false;
        state.maintenances = action.payload;
      })
      .addCase(getMaintenancesByAuthentication.rejected, (state) => {
        state.isMaintenancesGetLoading = false;
        state.isMaintenanceGetSuccess = false;
        state.isMaintenanceGetError = true;
      })
      .addCase(getMaintenanceById.pending, (state) => {
        state.isMaintenanceGetLoading = true;
        state.isMaintenanceGetSuccess = false;
        state.isMaintenanceGetError = false;
      })
      .addCase(getMaintenanceById.fulfilled, (state, action) => {
        state.isMaintenanceGetLoading = false;
        state.isMaintenanceGetSuccess = true;
        state.isMaintenanceGetError = false;
        state.maintenance = action.payload;
      })
      .addCase(getMaintenanceById.rejected, (state) => {
        state.isMaintenanceGetLoading = false;
        state.isMaintenanceGetSuccess = false;
        state.isMaintenanceGetError = true;
      })
      .addCase(deleteMaintenance.pending, (state) => {
        state.isMaintenanceDeleteLoading = true;
        state.isMaintenanceDeleteSuccess = false;
        state.isMaintenanceDeleteError = false;
      })
      .addCase(deleteMaintenance.fulfilled, (state, action) => {
        state.isMaintenanceDeleteLoading = false;
        state.isMaintenanceDeleteSuccess = true;
        state.isMaintenanceDeleteError = false;
        state.maintenances.results = 
        state.maintenances.results.filter((maintenance) => maintenance.id !== +action.payload);
      })
      .addCase(deleteMaintenance.rejected, (state) => {
        state.isMaintenanceDeleteLoading = false;
        state.isMaintenanceDeleteSuccess = false;
        state.isMaintenanceDeleteError = true;
      })
      .addCase(updateMaintenance.pending, (state) => {
        state.isMaintenanceUpdateLoading = true;
        state.isMaintenanceUpdateSuccess = false;
        state.isMaintenanceUpdateError = false;
      })
      .addCase(updateMaintenance.fulfilled, (state, action) => {
        state.isMaintenanceUpdateLoading = false;
        state.isMaintenanceUpdateSuccess = true;
        state.isMaintenanceUpdateError = false;
        state.maintenances.results = state.maintenances?.results.map((maintenance) =>
        maintenance.id === action.payload.id ? action.payload : maintenance);
        state.maintenance =  action.payload;
        state.files =  [];
      })
      .addCase(updateMaintenance.rejected, (state) => {
        state.isMaintenanceUpdateLoading = false;
        state.isMaintenanceUpdateSuccess = false;
        state.isMaintenanceUpdateError = true;
      }).addCase(getPagination.pending, (state) => {
      state.isMaintenancesGetLoading = true;
      state.isMaintenanceGetSuccess = false;
      state.isMaintenanceGetError = false;
    })
    .addCase(getPagination.fulfilled, (state, action) => {
      state.isMaintenancesGetLoading = false;
      state.isMaintenanceGetSuccess = true;
      state.isMaintenanceGetError = false;
      state.maintenances = action.payload;
    })
    .addCase(getPagination.rejected, (state) => {
      state.isMaintenancesGetLoading = false;
      state.isMaintenanceGetSuccess = false;
      state.isMaintenanceGetError = true;
    }).addCase(addAttachmentTicket.pending, (state) => {
      state.isDeleteTicketAttachmentSuccess = false;
    })
    .addCase(addAttachmentTicket.fulfilled, (state , action) => {
      state.isDeleteTicketAttachmentSuccess = true;
      state.attachments = [...state.attachments, action.payload]

    })
    .addCase(addAttachmentTicket.rejected, (state) => {
      state.isDeleteTicketAttachmentSuccess = false;
    })
    .addCase(editAttachmentTicket.pending, (state) => {
      state.isDeleteTicketAttachmentSuccess = false;
    })
    .addCase(editAttachmentTicket.fulfilled, (state , action) => {
      state.isDeleteTicketAttachmentSuccess = true;
      state.attachments = state.attachments.map(attachment => attachment.id === action.payload.id ? action.payload : attachment)

    })
    .addCase(editAttachmentTicket.rejected, (state) => {
      state.isDeleteTicketAttachmentSuccess = false;
    })
    .addCase(deleteAttachmentTicket.pending, (state) => {
      state.isDeleteTicketAttachmentSuccess = false;
    })
    .addCase(deleteAttachmentTicket.fulfilled, (state , action) => {
      state.isDeleteTicketAttachmentSuccess = true;
      console.log(action.payload);
      state.attachments = state.attachments.filter(attachment => attachment.id !== action.payload.attachmentId)

    })
    .addCase(deleteAttachmentTicket.rejected, (state) => {
      state.isDeleteTicketAttachmentSuccess = false;
    })
},
});

export const {
reset,
openMaintenanceModalCreate,
closeMaintenanceModalCreate,
openMaintenanceModalDelete,
closeMaintenanceModalDelete,
setMaintenanceId,
setTab,
setTicketType,
setTitle,
setDescription,
setPriority,
setCategory,
setMaintenaceType,
setSignature,
setEndDate,
setAssignedTo,
setAssigned,
setLocation,
setAsset,
setTime,
setStatus,
setMaintenance,
setStartedDate,
setDueFinish,
setCompletedDate,
setIsShowCost,
openMaintenanceModalUpdate,
closeMaintenanceModalUpdate,
isMaintenanceGetLoading,
resetMaintenance,
setFrequencyNumber,
setFrequency,
setSearchValue,
addAttachment,
setFiles,
getAttachment,
setStartDate,
setPMFilterSublocation,
setPMFilterSite,
setPMFiltertype,
setPMFilterCategory,
setCurrentPage,
setRowPerPage,
setStaff,
setFilterStatus
} = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
