/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */

import React, { useEffect } from 'react'
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../Ui/Card'
import { getWorkOrderInfo } from '../../../redux/features/qrCode/qrCodeSlice';
// import { BASE_URL } from '../../../utils/axios';

function TicketView() {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const ticket_number = query.get('ticket_number');
    const dispatch = useDispatch();
    const { 
        tkt
    } = useSelector((state) => state.QRService);

    useEffect(() => {
        dispatch(getWorkOrderInfo(ticket_number))
    }, [ticket_number])
    
  return (
    <>
    <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-0">
    {/* <div className="col col-lg-4 col-md-4 col-sm-4 col-xs-12  mb-2 logo float-left" 
    style={{marginTop :"-35px"}}
    >
        <img src={logo}  alt='logo' height="100" width="150"/>
    </div> */}
    <div className="col-lg-12 col-sm-12 col-md-12 mt-5 col-xs-12">
        <h1 style={{fontWeight : "400" , textAlign:"center"}}>Facility Service Request</h1>
    </div>
</div>
<div className="mt-5">
    <center>
        <div className="col col-lg-12 col-sm-12 col-xs-12 col-md-12">
        <h5 className="clr-green" id="rating-success"> </h5>
        <div className="card col-lg-7 col-sm-12 col-xs-12 col-md-12 text-left" 
            style={{display : "block"}}
        >
            <Card >
                <div className="card-header ">
                <h4 className="pt-1"><b>Requester details</b></h4>
            </div>
            <div className="card-body pb-2">
                <div className="row medium-title" >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Name:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                                {tkt?.service_request?.name}
                            </span>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Email:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            
                        {tkt?.service_request?.email}
                            
                            </span>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Phone:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                        {tkt?.service_request?.phone}
                            
                            </span>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Description:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            
                        {tkt?.description || "Unassigned"}
                        </span>
                    </div>
                </div>
            </div>
            </Card>
        </div>

        <div className="card col-lg-7 col-sm-12 col-xs-12 col-md-12 text-left">
        <Card>
            <div className="card-header medium-title">
                <span ><b>Ticket details</b></span>
            </div>
            <div className="card-body pb-2">
                <input type="hidden" value="t_data.ticket_number" id="ticket-number" />
                <div className="row medium-title" >

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Ticket Number:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                        {tkt?.ticket_number}
                        </span>

                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Ticket Title:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                        {tkt?.title}
                            </span>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Asset:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6"> 
                        {tkt?.asset?.name  || "Unassigned"}
                        </span>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Site:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6"> 
                        {tkt?.location?.site || "Unassigned" }
                        </span>
                    </div>
                    {
                    tkt?.sub_location && <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Location:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                        {tkt?.sub_location?`${tkt?.sub_location?.room && tkt?.sub_location?.room}${tkt?.sub_location?.suite && `, ${tkt?.sub_location?.suite}`}${tkt?.sub_location?.level && `, ${tkt?.sub_location?.level}`}${tkt?.sub_location?.building && `, ${tkt?.sub_location?.building}`}` : "Unassigned"}
                        </span>
                    </div>
                    }
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Requested on:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                        {moment(tkt?.modified_at).format('MMMM Do YYYY, h:mm:ss a')}
                        </span>
                    </div>
                    {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12" 
                        style={{display: "flex" , alignItems : "center" , justifyContent : "space-between"}}>
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Assigned To:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                        {tkt?.assigned_to?.length > 0 ? tkt?.assigned_to?.map(person => (
                                <span key={person?.id}>{`${person?.name} (${person?.role === "CliAdm" ? "Admin" : person?.role })`}</span>
                            )) : "Unassigned"}
                        </span>
                    </div> */}
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Status:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            
                        {tkt?.status || "Unassigned"}
                        </span>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Category:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            
                        {tkt?.category || "Unassigned"}
                        </span>
                    </div>
                    {tkt?.attachment?.length > 0 && <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 row">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Attachment:</label>
                        {tkt?.attachment?.map(item =>  
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xs-6" title={item?.name}>
                        <a target="_blank" rel="noopener noreferrer" href={item?.photo}>
                        <img style={{width:"100px" , height:"60px"}} src={item?.photo} alt={item?.name} />
                        </a>
                        </div>
                        )
                       }
                    </div>}
                </div>
            </div>
            </Card >
        </div>
    </div>
    </center>
</div>
</>
  )
}

export default TicketView