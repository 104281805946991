/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */

import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompletedDate,
  setSignature,
  setStartDate,
  setStatus,
} from '../../../../redux/features/maintenance/maintenanceSlice';
import {
  createLog,
  deleteLog,
  resetLog,
  setCreatedBy,
  setDescription,
  setHourlyRate,
  setHours,
  setMinutes,
  setWorkDate,
  updateLog,
} from '../../../../redux/features/ticketLog/ticketLogSlice';
import { SelectPersons } from '../../../../components/select/select';
import Signature from '../../../../components/signature/Signature';

function StatusTimeLog({ id }) {
  const [showEdit, setShowEdit] = useState();
  const [isShowSignModal, setIsShowSignModal] = React.useState(false);

  const { status, startDate, completed_date , signature } = useSelector((state) => state.maintenance);
  const teams = useSelector((state) => state.teams?.teams?.results);
  const dispatch = useDispatch();
  const handelStatus = (e) => {
    dispatch(setStatus(e.target.value));
    dispatch(setSignature(null))
  };

  const [isError, setIsError] = useState(false);
  const handleStartedDate = (e) => {
    dispatch(setStartDate(e.target.value));
  };
  const handleCompletedDate = (e) => {
    dispatch(setCompletedDate(e.target.value));
  };
  const handleHours = (e) => {
    dispatch(setHours(e.target.value));
    setIsError(false);
  };
  const handleMinutes = (e) => {
    dispatch(setMinutes(e.target.value));
    setIsError(false);
  };
  // const handleCreatedBy = (e)=>{
  // dispatch(setCreatedBy(e.target.value))
  // }
  const handleWorkDate = (e) => {
    dispatch(setWorkDate(e.target.value));
  };
  const handelDescription = (e) => {
    dispatch(setDescription(e.target.value));
  };
  const handleHourlyRate = (e) => {
    dispatch(setHourlyRate(e.target.value));
    setIsError(false);
  };

  const { description, hours, minutes, assigned_to, hourly_rate, work_date, logs } = useSelector(
    (state) => state.logTime,
  );

  const data = {
    description,
    hours,
    minutes,
    assigned_to: assigned_to?.value,
    hourly_rate,
    work_date: moment(work_date).format(),
  };

  const handelEdit = (log) => {
    setShowEdit(log.id);
    dispatch(setHours(log.hours));
    dispatch(setMinutes(log.minutes));
    dispatch(setCreatedBy(log.assigned_to?.id));
    dispatch(setWorkDate(log.work_date));
    dispatch(setDescription(log.description));
    dispatch(setHourlyRate(log.hourly_rate));
  };
  const handelDelete = async (tId) => {
    await dispatch(deleteLog(tId));
    dispatch(resetLog());
    setShowEdit();
  };
  const handelCancel = () => {
    setShowEdit();
    dispatch(resetLog());
  };

  const handelAddTime = async () => {
    if (work_date && assigned_to) {
      if (hours >= 0 && minutes >= 0 && hourly_rate >= 0) {
        await dispatch(createLog({ id, data }));
        dispatch(resetLog());
      } else {
        setIsError(true);
      }
    }
  };

  const handelUpdate = async (tId) => {
    if (work_date && assigned_to) {
      if (hours >= 0 && minutes >= 0 && hourly_rate >= 0) {
        await dispatch(updateLog({ ...data, assigned_to, id: tId }));
        dispatch(resetLog());
        setShowEdit();
      } else {
        setIsError(true);
      }
    }
  };

  const totalCost = logs
    ?.reduce((accumulator, currentValue) => +accumulator + +currentValue?.labor_cost, 0)
    .toFixed(2);
  const totHours = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.hours,
    0,
  );
  const totalMinutes = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.minutes,
    0,
  );
  const totalHours = Math.floor(totHours + totalMinutes / 60).toFixed();
  const min = totalMinutes % 60;

  const { user } = useSelector((state) => state.user);
  return (
    <div id="time-tab" role="tabpanel" aria-labelledby="custom-tabs-two-time-tab">
      <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 ">
        <div className=" card-header col-lg-12 px-0">
          <label className="font-weight-normal">
            Status <span className="font-danger">*</span>
          </label>
          <select
            className="form-control select-dash custom-select2 mb-2"
            name="priority2"
            id="priority2"
            onChange={handelStatus}
            value={status}
          >
            <option value="" disabled selected>
              --SELECT--
            </option>
            {['Open', 'In-Progress', 'On-Hold', 'Completed'].map(
              (val) => (
                <option value={val} key={val}>
                  {val}
                </option>
              ),
            )}
          </select>

          {/* {status && status === "Started"&& */}
          <div className="col-12 row mb-2">
            <label className="font-weight-normal col-12">Started Date</label>
            {/* <span className="font-danger">*</span></label> */}
            <input
              required={status === 'In-Progress'}
              disabled={status !== 'In-Progress'}
              type="datetime-local"
              className="form-control"
              name="due_finish2"
              id="due_finish2"
              value={moment(startDate).format('YYYY-MM-DDTHH:mm')}
              onChange={handleStartedDate}
            />
          </div>
          {status === 'Completed' && (
            <Signature
              signature={signature}
              setSignature={setSignature}
              isShowSignModal={isShowSignModal}
              setIsShowSignModal={setIsShowSignModal}
            />
          )}

          {/* {status && status === "Completed" && */}
          <div className="col-12 row mb-2">
            <label className="font-weight-normal col-12">Completed Date</label>
            {/* <span className="font-danger">*</span></label> */}
            <input
              required={status === 'Completed'}
              disabled={status !== 'Completed'}
              type="datetime-local"
              className="form-control"
              name="due_finish2"
              id="due_finish2"
              value={moment(completed_date).format('YYYY-MM-DDTHH:mm')}
              onChange={handleCompletedDate}
            />
          </div>
          <hr />
          <div className='row'>
          <div className="card-title pl-2 col-sm-6 col-xs-12">
            <b>Time Log</b>{' '}
            <span>
              {totalHours} hr and {min} Min
            </span>
          </div>
          <div className="card-title pl-2 col-sm-6 col-xs-12" >
            <b>Total Labor Cost</b>
            <strong className="red" id="total_labor_cost">
              {' '}
              ${totalCost}
            </strong>
            <input type="hidden" id="value_labor_cost" value="0" />
          </div>
          </div>
        </div>

        <div className="card-body p-0">
          {!showEdit && (
            <div className="col-12 mb-2 p-0">
              <i className="col-12">How much time was spent on this work order?</i>
              <div className="col-12">
                <i className="fa fa-clock-o"></i>
                <input
                  type="number"
                  min="0"
                  className="form-control "
                  style={{ width: '4em', display: 'inline' }}
                  name="workdone_h"
                  id="workdone_h"
                  placeholder="Hours Done"
                  value={hours}
                  onChange={handleHours}
                />{' '}
                <strong className="red">hr</strong>
                <input
                  type="number"
                  min="0"
                  className="form-control "
                  style={{ width: '4em', display: 'inline' }}
                  name="workdone_m"
                  id="workdone_m"
                  placeholder="Minutes Done"
                  value={minutes}
                  onChange={handleMinutes}
                />{' '}
                <strong className="red">Min</strong> to{' '}
                <div style={{ width: '300px', display: 'inline-block', margin: '5px' }}>
                  <SelectPersons
                    customForLogTime
                    teams={teams}
                    assignedTo={assigned_to}
                    setAssignedTo={setCreatedBy}
                  />
                </div>
                <div className='col-lg-12 col-sm-12 row' style={{ margin: '5px' }}>
                  on{' '}
                  <input
                    type="datetime-local"
                    className="form-control "
                    name="work_date"
                    id="work_date"
                    placeholder="Work Date"
                    style={{ width: '80%', marginLeft:"10px" }}
                    value={work_date}
                    onChange={handleWorkDate}
                  />
                </div>
              </div>
              <span
                className="font-danger float-left col-lg-12 col-md-12 col-sm-12"
                id="timelog_error"
              ></span>
              <div className="pl-2">
                <textarea
                  className="form-control mb-2"
                  name="work_description"
                  id="work_description"
                  placeholder="Add description"
                  value={description}
                  onChange={handelDescription}
                ></textarea>
                Hourly Rate{' '}
                <input
                  type="hidden"
                  id="user_hourly_rate_user.id"
                  value="user.profile__hourly_rate"
                />
                <input
                  name="hourly_rate"
                  type="number"
                  min="0"
                  className="form-control"
                  style={{ width: '5em', display: 'inline' }}
                  id="hourly_rate"
                  placeholder="$0"
                  value={hourly_rate}
                  onChange={handleHourlyRate}
                />{' '}
                {isError && <span className="red">Please Add A Positive Numbers</span>}
                <button
                  id="timelog_button"
                  name="timelog_button"
                  className="btn btn-primary ml-2"
                  style={{ height: '2.5em', float: 'right' }}
                  onClick={handelAddTime}
                  type="button"
                >
                  <span className="button__text">Add Time</span>
                </button>
              </div>
            </div>
          )}

          <hr />
          <div className="col-12 row">
            <div className="my-auto">
              <b>Log History</b>
            </div>
          </div>
          <div id="timelog_history">
            <input type="hidden" id="edit-timelog-id" />
            {logs &&
              logs.map((log) => {
                return (
                  <div key={log.id} className="col-12 p-1 mb-2" id="log-view-history.id">
                    {!showEdit && (
                      <>
                        <div>
                          <i className="fa fa-clock-o"></i>
                          <strong className="red">{log.hours} hr </strong> and{' '}
                          <strong className="red">{log.minutes} min</strong> to{' '}
                          <strong className="red">{log?.assigned_to?.name}</strong> on{' '}
                          <strong className="red">
                            {moment(log?.work_date).format('MMMM Do YYYY, h:mm:ss a')}
                          </strong>
                          {!showEdit && user?.id === log?.created_by?.id && (
                            <button
                              type="button"
                              title="Edit"
                              className=" ml-auto float-right secondary-btn btn"
                              onClick={() => handelEdit(log)}
                            >
                              <b>Edit</b>
                            </button>
                          )}
                        </div>
                        <div className="p-2">{log.description}</div>
                        <div className="pl-2">
                          Labor Cost: <strong className="red">${log.labor_cost}</strong>
                        </div>
                      </>
                    )}
                    {log.id === showEdit && (
                      <div className="col-12 p-1 mb-2" id="log-edit-history.id">
                        <div>
                          <i className="fa fa-clock-o mx-2"></i>
                          <input
                            type="number"
                            className="form-control "
                            style={{ width: '3em', display: 'inline' }}
                            name="workdone_h_history.id"
                            id="workdone_h_history.id"
                            placeholder="Hours Done"
                            defaultValue={hours || log.hours}
                            onChange={handleHours}
                          />{' '}
                          <strong className="red">hr</strong>{' '}
                          <input
                            type="number"
                            className="form-control "
                            style={{ width: '4em', display: 'inline' }}
                            name="workdone_m_history.id"
                            id="workdone_m_history.id"
                            placeholder="Minutes Done"
                            defaultValue={minutes || log.minutes}
                            onChange={handleMinutes}
                          />{' '}
                          <strong className="red">Min</strong> to{' '}
                          <strong className="red"> {log?.assigned_to?.name} </strong> on
                          <input
                            type="datetime-local"
                            className="form-control "
                            name="work_date_history.id"
                            id="work_date_history.id"
                            placeholder="Work Date"
                            onChange={handleWorkDate}
                            defaultValue={
                              moment(log?.work_date).format('YYYY-MM-DDTHH:mm') ||
                              moment(log.work_date).format('YYYY-MM-DDTHH:mm')
                            }
                            style={{ width: '10em', display: 'inline' }}
                          />
                        </div>
                        <div className="p-2">
                          <textarea
                            className="form-control mb-2"
                            name="work_description_history.id"
                            id="work_description_history.id"
                            placeholder="Task Description"
                            onChange={handelDescription}
                            defaultValue={description || log.description}
                          ></textarea>
                          {isError && <span className="red">Please Add A Positive Numbers</span>}
                        </div>
                        <div className="btn">
                          <button
                            onClick={() => handelDelete(log.id)}
                            type="button"
                            data-toggle="modal"
                            data-target="#modal-timelog"
                            title="Delete Time Log"
                            className="fas fa-trash float-right mx-2 my-1 red"
                          ></button>
                          <button
                            onClick={handelCancel}
                            type="button"
                            title="Cancel"
                            id="log-view-btn-history.id"
                            className=" ml-auto float-right"
                          >
                            <b>Cancel</b>
                          </button>

                          <button
                            type="button"
                            title="Save"
                            className=" ml-auto float-right"
                            onClick={() => handelUpdate(log.id)}
                          >
                            <i className="fas fa-check mx-2 my-1 red"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusTimeLog;
