import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import logService  from './ticketLogService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // Ticket Log
  logs :[],
  log : {},
  summary :{},


  // Log fields
  description: "",
  descEdit : "",
  hours : 0,
  minutes : 0,
  assigned_to : "",
  hourly_rate : 0,
  work_date : "",
  
  

  // success states
  isLogGetSuccess: false,
  isLogCreateSuccess: false,
  isLogDeleteSuccess: false,
  isLogUpdateSuccess: false,
 

  // loading states
  isLogCreateLoading: false,
  isLogGetLoading: false,
  isLogDeleteLoading: false,
  isLogUpdateLoading: false,


  // error states
  isLogGetError: false,
  isLogCreateError: false,
  isLogDeleteError: false,
  isLogUpdateError: false,


};

export const createLog = createAsyncThunk('createLog', async (data, thunkAPI) => {
  const createLogRes = await smartTryCatch(logService.createLog, data, thunkAPI);
  return createLogRes;
});

export const getLogsByAuthentication = createAsyncThunk(
  'getLogsByAuthentication',
  async (data, thunkAPI) => {
    const getLogs = await smartTryCatch(
      logService.getLogsByAuthentication,
      data,
      thunkAPI,
    );
    return getLogs;
  },
);

export const getLogById = createAsyncThunk('getLogById', async (data, thunkAPI) => {
  const getLog = await smartTryCatch(logService.getLogById, data, thunkAPI);
  return getLog;
});


export const deleteLog = createAsyncThunk('deleteLog', async (data, thunkAPI) => {
  const deleteLogRes = await smartTryCatch(logService.deleteLog, data, thunkAPI);
  return deleteLogRes;
});

export const updateLog = createAsyncThunk('updateLog', async (data, thunkAPI) => {
  const updateLogRes = await smartTryCatch(logService.updateLog, data, thunkAPI);
  return updateLogRes;
});


export const logSlice = createSlice({
  name: 'logTime',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isLogCreateError = false;
      state.isLogCreateSuccess = false;
      state.isLogCreateLoading = false;
      state.isLogGetError = false;
      state.isLogGetSuccess = false;
      state.isLogGetLoading = false;
      state.isLogDeleteError = false;
      state.isLogDeleteSuccess = false;
      state.isLogDeleteLoading = false;
      state.isLogUpdateError = false;
      state.isLogUpdateSuccess = false;
      state.isLogUpdateLoading = false;
      // state.Log = null;
    },
   
    setDescription : (state, action) => {
      state.description = action.payload;
    },
    setDescEdit : (state, action) => {
      state.descEdit = action.payload;
    },

  //   hours : "",
  // minutes : "",
  // assigned_to : "",
  // hourly_rate : "",
  // work_date : "",

  setHours : (state, action) => {
    state.hours = action.payload;
  },
  setMinutes : (state, action) => {
    state.minutes = action.payload;
  },
  setCreatedBy : (state, action) => {
    state.assigned_to = action.payload;
  },
  setHourlyRate : (state, action) => {
    state.hourly_rate = action.payload;
  },
  setWorkDate : (state, action) => {
    state.work_date = action.payload;
  },
    resetLog: (state) => {
      state.description = ""
      state.descEdit = ""
      state.hours= 0
      state.minutes= 0
      state.assigned_to= ""
      state.hourly_rate= 0
      state.work_date= ""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLog.pending, (state) => {
        state.isLogCreateLoading = true;
        state.isLogCreateSuccess = false;
        state.isLogCreateError = false;
      })
      .addCase(createLog.fulfilled, (state, action) => {
        state.isLogCreateLoading = false;
        state.isLogCreateSuccess = true;
        state.isLogCreateError = false;
        state.logs.push(action.payload);
      })
      .addCase(createLog.rejected, (state) => {
        state.isLogCreateLoading = false;
        state.isLogCreateSuccess = false;
        state.isLogCreateError = true;
      })
      .addCase(getLogsByAuthentication.pending, (state) => {
        state.isLogGetLoading = true;
        state.isLogGetSuccess = false;
        state.isLogGetError = false;
      })
      .addCase(getLogsByAuthentication.fulfilled, (state, action) => {
        state.isLogGetLoading = false;
        state.isLogGetSuccess = true;
        state.isLogGetError = false;
        state.logs = action.payload;
      })
      .addCase(getLogsByAuthentication.rejected, (state) => {
        state.isLogGetLoading = false;
        state.isLogGetSuccess = false;
        state.isLogGetError = true;
      })
      .addCase(getLogById.pending, (state) => {
        state.isLogGetLoading = true;
        state.isLogGetSuccess = false;
        state.isLogGetError = false;
      })
      .addCase(getLogById.fulfilled, (state, action) => {
        state.isLogGetLoading = false;
        state.isLogGetSuccess = true;
        state.isLogGetError = false;
        state.log = action.payload;
      })
      .addCase(getLogById.rejected, (state) => {
        state.isLogGetLoading = false;
        state.isLogGetSuccess = false;
        state.isLogGetError = true;
      })
      .addCase(deleteLog.pending, (state) => {
        state.isLogDeleteLoading = true;
        state.isLogDeleteSuccess = false;
        state.isLogDeleteError = false;
      })
      .addCase(deleteLog.fulfilled, (state, action) => {
        state.isLogDeleteLoading = false;
        state.isLogDeleteSuccess = true;
        state.isLogDeleteError = false;
        state.logs = state.logs.filter((log) => log.id !== action.payload);
      })
      .addCase(deleteLog.rejected, (state) => {
        state.isLogDeleteLoading = false;
        state.isLogDeleteSuccess = false;
        state.isLogDeleteError = true;
      })
      .addCase(updateLog.pending, (state) => {
        state.isLogUpdateLoading = true;
        state.isLogUpdateSuccess = false;
        state.isLogUpdateError = false;
      })
      .addCase(updateLog.fulfilled, (state, action) => {
        state.isLogUpdateLoading = false;
        state.isLogUpdateSuccess = true;
        state.isLogUpdateError = false;
        state.logs = state.logs?.map((log) =>
        log.id === action.payload.id ? action.payload : log,
        );
      })
      .addCase(updateLog.rejected, (state) => {
        state.isLogUpdateLoading = false;
        state.isLogUpdateSuccess = false;
        state.isLogUpdateError = true;
      })
  
},
});

export const {
  setDescription,
  resetLog,
  setDescEdit,
  setHours,
  setMinutes,
  setCreatedBy,
  setHourlyRate,
  setWorkDate,
} = logSlice.actions;

export default logSlice.reducer;