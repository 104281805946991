/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
/* eslint-disable no-lonely-if */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */

import React from 'react';
import Chart from 'react-apexcharts';

function WoOrderPieChart ({data , pdfReport}) {
  const series = data;
  const options = {
    colors: ['#A7C9EC', '#215F9A'],
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: ['PM', 'WO'],
    legend: {
      show:true,
      inverseOrder:true,
      position: 'right',
      fontSize: '18px',
      fontFamily: 'Poppins',
      fontWeight: 600,
      lineHeight:'27px',
      labels: {
        colors: ['#000000','#000000'], // Color for legend text
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fonFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        colors:['black','white']
      },
      dropShadow: {
        enabled: false // Disable the text shadow
      },
    },
    tooltip:{
      enabled:false
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -25,
          minAngleToShowLabel: 10,
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 270,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div>
      <div id="chart">
        {!pdfReport && <Chart options={options} series={series} type="pie" width={380} />}
        {pdfReport && <Chart options={options} series={series} type="pie" width={350} />}
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default WoOrderPieChart;
