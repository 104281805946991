import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';


const getTickectChecklistById = async (checklistId) => {
  const res = await http.get(`${BASE_URL}/ticket-checklist/${checklistId}/`);
  return assert(res, res.data, 'Retrieval of checklist by its ID failed', res);
};

const createChecklist = async (payload) => {
  const res = await http.post(`${BASE_URL}/ticket-checklist/${payload.id}/`
  , payload.checklistData);

  return assert(res, res.data, 'Creation of checklist failed', res);
};

const deleteChecklist = async (checklistId) => {
  const res = await http.delete(`${BASE_URL}/checklist/${checklistId}/`);
  return assert(res, checklistId, 'Deletion of checklist failed', res);
};


const deleteBulkChecklist = async (payload) => {
  console.log("payload",payload);
  const res = await http.deleteBulk(`${BASE_URL}/checklist/bulk-delete/`, payload);
  return assert(res, payload, 'Deletion of checklist failed', res);
};

const updateChecklist = async (payload) => {
  const res = await http.put(
    `${BASE_URL}/ticket-checklist/${payload.id}/`,
    payload.checklistData,
  );
  return assert(res, res.data, 'Update of checklist failed', res);
};

const updateChecklistResult = async (payload) => {
  const res = await http.put(
    `${BASE_URL}/ticket-checklist-result/${payload.id}/`,
    payload,
);
  return assert(res, res.data, 'Update of checklist failed', res);
};

const updateChecklistItem = async (payload) => {
  const res = await http.put(
    `${BASE_URL}/checklistitem/update/${payload.id}/`,
    payload,
  );
  return assert(res, res.data, 'Update of checklist failed', res);
};

const deleteChecklistItem = async (data) => {
  const res = await http.deleteBulk(`${BASE_URL}/checklistitem/update/${data.itemId}/`);
  return assert(res, data, 'Deletion of checklist failed', res);
};
const updateChecklistItemAttachment = async (payload) => {
  const res = await http.patch(
    `${BASE_URL}/checklistitem-attachment/${payload.id}/`,
    payload.formData,
  );
  return assert(res, res.data, 'Update of checklist failed', res);
};

const checklistService = {
  getTickectChecklistById,
  createChecklist,
  deleteChecklist,
  updateChecklist,
  updateChecklistResult,
  updateChecklistItem,
  updateChecklistItemAttachment,
  deleteChecklistItem,
  deleteBulkChecklist
};

export default checklistService;
