/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable  react/self-closing-comp */

import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useTable, useExpanded, useGlobalFilter } from 'react-table'
import { useDispatch, useSelector } from 'react-redux';
import classes from "../../../components/assetsTable/assetsTable.module.css"
import Loader from '../../../components/loader/loader';
import { closeWorkOrderModalDelete, deleteWorkOrder, openWorkOrderModalDelete } from '../../../redux/features/workOrder/workOrderSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';


function WOTable({ meterData, getSelectedRowwValues, iSShowFilter,
  iSShowCheckboxs,
  setISShowCheckboxs }) {
  const [rowId, setRowId] = useState(null)
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { workOrderModalDelete } = useSelector(state => state.workOrder);
  const handleDelete = () => {
    dispatch(deleteWorkOrder(rowId.id))
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Work Order Title',
        accessor: 'title',
      },
      {
        Header: 'Work Order #',
        accessor: 'ticketNumber',
      },
      {
        Header: 'Assigned To',
        Cell: ({ row }) => {
          return (
            <span className={classes.imagesWrapper}>
              {row?.original?.assignedPersons !== "—" ?
                row?.original?.assignedPersons?.map((item) => {
                  return (
                    <img
                      className={classes.assignedImage}
                      src={item?.image}
                      alt={item?.name}
                      title={item?.name}
                    />)
                })
                : <span className='ml-4'>—</span>
              }
            </span>
          )

        },
      },

      {
        Header: 'Due Date',
        Cell: ({ row }) => {
          const date = moment(row?.original?.start_date).format("YYYY-MM-DD") ===
            "Invalid date" ? "—" : moment.utc(row?.original?.start_date).format("YYYY-MM-DD")
          return (
            <>
              <span>{date}</span>
              {" "}
              {row?.original?.status !== "Completed" && (date !== "—" && (moment().diff(moment(row?.original?.start_date).format("YYYY-MM-DD"), "seconds") < 0 ?
                <i className="far fa-question-circle big-font gcolor ml-2" >
                  <span className="tooltiptext bold"
                    style={{ width: "fit-content", padding: "10px", fontWeight: "bold" }}>
                    {moment(row?.original?.start_date).fromNow(true)} before due
                  </span>
                </i> : <i className="fas fa-exclamation-circle overdue" >
                  <span className="tooltiptext" style={{ width: "fit-content", padding: "10px" }}>
                    {moment(row?.original?.start_date).fromNow(true)} overdue
                  </span>
                </i>)
              )}
            </>
          )
        },
      },
      {
        Header: 'Created Date',
        Cell: ({ row }) => {
          return  moment(row?.original?.created_at).format("YYYY-MM-DD")
        } 
      },
      {
        Header: 'Location',
        accessor: "location",
      },
      {
        Header: 'Asset',
        accessor: 'asset',
      },

      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Priority',
        Cell: ({ row }) => {
          let priorityClass = classes.hPriority
          if (row.original.priority === "Medium") {
            priorityClass = classes.mPriority
          }
          if (row.original.priority === "Low ") {
            priorityClass = classes.lPriority
          }
          return (
            <>
              {row.original.priority ?
                <span className={`${priorityClass} ${classes.labels}`}>{row.original.priority}</span> :
                <span className={classes.pr}>—</span>
              }
              {/* {row.original.category ? <span className={classes.category} title={row.original.category}>{row.original.category?.length > 10 ? `${row.original.category.slice(0, 10)}...` : row.original.category}</span> :
                <span className={classes.pr}>—</span>
              } */}
            </>
          )

        },
      },
      {
        id: 'delete-table-column',
        Cell: ({ row }) => {
          let deleteBtn = <span title='Delete WO'
            onClick={() => {
              dispatch(openWorkOrderModalDelete())
              setRowId(row.original)
            }}
            id={row.id} className={`tertiary-btn btn ${classes['del-bt']}`}>
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          if ((user?.role === "Technical Team") ||
            (user?.role === "Requester") ||
            (user?.role === "Manager" && row.original?.createdBy !== "Manager")) {
            deleteBtn = ""
          }
          return deleteBtn
        },
      },

    ],
    [user]
  )

  const data = meterData
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    allColumns,
  } = useTable({
    columns,
    data,
    manualPagination: true
  }, useGlobalFilter, useExpanded)

  const { isWorkOrdersGetLoading, } = useSelector(state => state.workOrder)
  return (
    <>
      {isWorkOrdersGetLoading ? (
        <Loader />
      ) : (
        <>
          {iSShowFilter && <div className={classes.filterTable}>
            <div className={classes.wraper}>
              <h4>Table columns</h4>
              <button type='button' onClick={() => setISShowCheckboxs(!iSShowCheckboxs)}>
                <FontAwesomeIcon icon={faAngleDown} />
              </button>
            </div>
            {iSShowCheckboxs && <div className={classes.checkboxs}>
              {/* Loop through columns data to create checkbox */}
              {allColumns.map((column) => {
                if (column.id !== 'delete-table-column') {
                  return <div key={column.id} className={classes.checkbox}>
                    <input type="checkbox" {...column.getToggleHiddenProps()} />
                    <span>{column.Header}</span>
                  </div>
                }
              })}
              <br />
            </div>}
          </div>}



          <div className={classes.scrollX}>
            <table {...getTableProps()} className={classes.table}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
              {rows.length !== 0 ? rows?.map((row) => {
                  prepareRow(row)
                  let statusClass;
                  if (row.original.status === 'Open') {
                    statusClass = classes.open;
                  }
                  if (row.original.status === 'In-Progress') {
                    statusClass = classes.inProgress;
                  }
                  if (row.original.status === 'Pending') {
                    statusClass = classes.pending;
                  }
                  if (row.original.status === 'On-Hold') {
                    statusClass = classes.onHold;
                  }
                  if (row.original.status === 'Completed') {
                    statusClass = classes.completed;
                  }
                  if (row.original.status === 'Declined') {
                    statusClass = classes.declined;
                  }
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        // const completedBDueOrNot = 
                        return <td
                          {...cell.getCellProps()}
                          onClick={() => getSelectedRowwValues(row.original, cell)}
                        >
                          {cell.column.Header === "Status" ? (row.original.status === "Completed" ? <span className={statusClass}>{cell.render('Cell')}
                            {moment(row.original.completedDate).diff(moment(row?.original?.start_date).format("YYYY-MM-DD"), "seconds") < 0 ?
                              <i className="fa fa-check-circle ml-2" style={{ color: "green" }}></i> :
                              <i className="fa fa-check-circle ml-2" style={{ color: "red" }}></i>}
                          </span> : <span className={statusClass}>{cell.render('Cell')}</span>) :
                            cell.column.Header === "Due Date" ? <span style={{ color: "#db1f48" }}>{cell.render('Cell')}</span> :
                              (cell.value === "" || cell.value === null) ? '—' : <div> {cell.render('Cell')}</div>}
                        </td>
                      })}
                    </tr>
                  )
                }) : <tr> <td className={classes.noResults} colSpan="9">No Results Found </td></tr>}
              </tbody>
            </table>
          </div>
          {workOrderModalDelete && (
            <PopUpDeleteModal
              showModal={workOrderModalDelete}
              handleClose={() => dispatch(closeWorkOrderModalDelete())}
              handleConfirm={handleDelete}
              modalTitle={`Delete ${rowId?.title}`}
              modalBodyText="Are you sure you want to delete this work order?"
            />
          )}
        </>
      )}
    </>
  )
}

export default WOTable




