// write everything here similar to the rest of the redux stores in the project.
import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getChecklistByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/checklist/`);
  return assert(res, res.data, 'Retrieval of checklist by its company ID failed', res);
};

const getChecklistById = async checklistId => {
  const res = await http.get(`${BASE_URL}/checklist/${checklistId}`);

  return assert(res, res.data, 'Retrieval of checklist by its ID failed', res);
};

const createChecklist = async data => {
  const res = await http.post(`${BASE_URL}/checklist/`, data);

  return assert(res, res.data, 'Creation of checklist failed', res);
};

const updateChecklist = async data => {
  const res = await http.put(`${BASE_URL}/checklist/${data.id}/`, data.userdata);
  return assert(res, res.data, 'Update of checklist failed', res);
};

const deleteChecklist = async checklistId => {
  const res = await http.delete(`${BASE_URL}/checklist/${checklistId}/`);
  return assert(res, checklistId, 'Deletion of checklist failed', res);
};

const deleteBulkChecklist = async (payload) => {
  console.log("payload",payload);
  const res = await http.deleteBulk(`${BASE_URL}/checklist/bulk-delete/`, payload);
  return assert(res, payload, 'Deletion of checklist failed', res);
};

const getPagination = async data => {
  const res = await http.get(
    `${BASE_URL}/checklist/?search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}`,
  );
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const checklistService = {
  getPagination,
  getChecklistByAuthentication,
  getChecklistById,
  createChecklist,
  updateChecklist,
  deleteChecklist,
  deleteBulkChecklist
};

export default checklistService;
