/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import moment from 'moment';
import React, { useState } from 'react';
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux';
import {
  ApproveAndReject,
  closeWorkOrderApprovalModalDecline,
  openWorkOrderApprovalModalDecline,
  setApprovalReason,
  setCompletedDate,
  setCompletionNotes,
  setSignature,
  setStartedDate,
  setStatus,
} from '../../../../redux/features/workOrder/workOrderSlice';
import {
  createLog,
  deleteLog,
  resetLog,
  setCreatedBy,
  setDescription,
  setHourlyRate,
  setHours,
  setMinutes,
  setWorkDate,
  updateLog,
} from '../../../../redux/features/ticketLog/ticketLogSlice';
import { SelectPersons } from '../../../../components/select/select';
import Signature from '../../../../components/signature/Signature';
import NewModal from '../../../../components/modals/NewModal';

function StatusTimeLog({ id }) {
  const [showEdit, setShowEdit] = useState();
  const [isShowSignModal, setIsShowSignModal] = React.useState(false);
  const { status, start_date, completed_date, completionNotes, signature, workOrder, approvalStatus, approvalDate,
    workOrderApprovalModalDecline, approvalReason } = useSelector((state) => state.workOrder);
  const teams = useSelector((state) => state.teams?.teams?.results);
  const dispatch = useDispatch();
  const handelStatus = (e) => {
    dispatch(setStatus(e.target.value));
    dispatch(setSignature(null))
    // if (e.target.value === 'Completed') {
    //   setIsShowSignModal(true);
    // }
    // if (e.target.value !== 'Started') {
    //   dispatch(setStartedDate(null));
    // } else if (e.target.value !== 'Completed') {
    //   dispatch(setCompletedDate(null));
    // }
  };

  const [isError, setIsError] = useState(false);
  const handleStartedDate = (e) => {
    dispatch(setStartedDate(e.target.value));
  };
  const handleCompletedDate = (e) => {
    dispatch(setCompletedDate(e.target.value));
  };
  const handelCompletionNotes = (e) => {
    dispatch(setCompletionNotes(e.target.value));
  };
  const handleHours = (e) => {
    dispatch(setHours(e.target.value));
    setIsError(false);
  };
  const handleMinutes = (e) => {
    dispatch(setMinutes(e.target.value));
    setIsError(false);
  };
  // const handleCreatedBy = (e)=>{
  // dispatch(setCreatedBy(e.target.value))
  // }
  const handleWorkDate = (e) => {
    dispatch(setWorkDate(e.target.value));
  };
  const handelDescription = (e) => {
    dispatch(setDescription(e.target.value));
  };
  const handleHourlyRate = (e) => {
    dispatch(setHourlyRate(e.target.value));
    setIsError(false);
  };

  const { description, hours, minutes, assigned_to, hourly_rate, work_date, logs } = useSelector(
    (state) => state.logTime,
  );

  const data = {
    description,
    hours,
    minutes,
    assigned_to: assigned_to?.value,
    hourly_rate,
    work_date: moment(work_date).format(),
  };
  const handelEdit = (log) => {
    setShowEdit(log.id);
    dispatch(setHours(log.hours));
    dispatch(setMinutes(log.minutes));
    dispatch(setCreatedBy(log.assigned_to?.id));
    dispatch(setWorkDate(log.work_date));
    dispatch(setDescription(log.description));
    dispatch(setHourlyRate(log.hourly_rate));
  };
  const handelDelete = async (tId) => {
    await dispatch(deleteLog(tId));
    dispatch(resetLog());
    setShowEdit();
  };
  const handelCancel = () => {
    setShowEdit();
    dispatch(resetLog());
  };

  const handelAddTime = async () => {
    if (work_date && assigned_to) {
      if (hours >= 0 && minutes >= 0 && hourly_rate >= 0) {
        await dispatch(createLog({ id, data }));
        dispatch(resetLog());
      } else {
        setIsError(true);
      }
    }
  };

  const handelUpdate = async (tId) => {
    if (work_date && assigned_to) {
      if (hours >= 0 && minutes >= 0 && hourly_rate >= 0) {
        await dispatch(updateLog({ ...data, assigned_to, id: tId }));
        dispatch(resetLog());
        setShowEdit();
      } else {
        setIsError(true);
      }
    }
  };

  const totalCost = logs
    ?.reduce((accumulator, currentValue) => +accumulator + +currentValue?.labor_cost, 0)
    .toFixed(2);
  const totHours = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.hours,
    0,
  );
  const totalMinutes = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.minutes,
    0,
  );
  const totalHours = Math.floor(totHours + totalMinutes / 60).toFixed();
  let min = Math.ceil(((totHours + totalMinutes / 60) % 1) * 60);
  if (min === 60) {
    min = 0;
  }


  const [hideButtons, setHideButtons] = useState(false)

  const handleAccept = async () => {
    await dispatch(ApproveAndReject({ id: workOrder?.id, approval_status: "Accepted" }))
    setHideButtons(true)
  }
  const handleDecline = async () => {
    if (approvalReason) {
      await dispatch(ApproveAndReject({
        id: workOrder?.id,
        approval_status: "Rejected",
        approval_decline_reason: approvalReason
      }))
      await dispatch(closeWorkOrderApprovalModalDecline())
      setHideButtons(true)

    } else {
      toast.warning('Please fill the reason and submit again', {
        toastId: 'reasonWarning',
      });
    }
  }

  const handelReason = (e) => {
    dispatch(setApprovalReason(e.target.value))
  }

  const { user } = useSelector((state) => state.user);

  const isWorkOrderValidator = workOrder?.location?.approvers?.length > 0 ? workOrder?.location?.approvers[0]?.user?.id === user?.id : false
  const isValidator = workOrder?.location?.approvers?.length > 0 ? workOrder?.location?.approvers[0]?.user?.id : false

  return (
    <div id="time-tab" role="tabpanel" aria-labelledby="custom-tabs-two-time-tab">
      <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 ">
        <div className=" card-header col-lg-12 px-0">
          {isWorkOrderValidator && (workOrder?.status === 'Complete (Pending Approval)' || workOrder?.status === 'Completed')  && <>
            <div className="col-lg-12 col-sm-12 pt-2 wo-approval">
              <h6>Work Order Approval</h6>
              <p>This work order marked as complete but still need your approval, please kindly validate to close the work order. </p>
              {workOrder?.status === 'Complete (Pending Approval)' 
              && !hideButtons && <div className='flex acc-dec'>
                <button
                  className="decline-btn mr-2"
                  type="button"
                  onClick={() => dispatch(openWorkOrderApprovalModalDecline())}
                >
                  <span className="button__text">Decline</span>
                </button>
                <button
                  className="accept-btn"
                  type="button"
                  onClick={handleAccept}
                >
                  <span className="button__text">Accept</span>
                </button>
              </div>}
              {approvalStatus === "Accepted" && status !== 'Complete (Pending Approval)' &&
              <div className='wo-approval-accepted-msg mb-2 mt-2'>You have accept this work order submission on {moment(approvalDate).format('DD MMMM YYYY HH:mm')}</div>
              }
              {approvalStatus === "Rejected" && status !== 'Complete (Pending Approval)' &&
              <div className='wo-approval-declined-msg mb-2 mt-2'>You declined this work order submission on {moment(approvalDate).format('DD MMMM YYYY HH:mm')}</div>
              }
            </div>
            <hr />
          </>
          }
          <label className="font-weight-normal">
            Status <span className="font-danger">*</span>
          </label>
          <select
            className="form-control select-dash custom-select2 mb-2"
            onChange={handelStatus}
            value={status}
          >
            <option value="" disabled selected>
              --SELECT--
            </option>
            {(!isValidator) ? ['Open', 'In-Progress', 'On-Hold', 'Completed', "Pending", "Declined"].map(
              (val) => {
                if (val === "Declined" || val === "Pending") {
                  return <option key={val} value={val} disabled>
                    {val}
                  </option>
                }
                return <option key={val} value={val}>
                  {val}
                </option>
              },
            ) : ['Open', 'In-Progress', 'On-Hold', 'Complete (Pending Approval)', "Pending", "Declined"].map(
              (val) => {
                if (val === "Declined" || val === "Pending") {
                  return <option key={val} value={val} disabled>
                    {val}
                  </option>
                }
                if (val === "Complete (Pending Approval)" && status === "Completed") {
                  return <option key="Completed" value="Completed">
                    Completed
                  </option>
                }
                return <option key={val} value={val}>
                  {val}
                </option>
              },
            )}
          </select>

          <div className="col-12 row mb-2">
            <label className="font-weight-normal col-12">Started Date</label>
            {/* <span className="font-danger">*</span></label> */}
            <input
              required={status === 'In-Progress'}
              disabled={status !== 'In-Progress'}
              type="datetime-local"
              className="form-control"
              value={start_date}
              onChange={handleStartedDate}
            />
          </div>

          {status === 'Completed' && (
            <Signature
              signature={signature}
              setSignature={setSignature}
              isShowSignModal={isShowSignModal}
              setIsShowSignModal={setIsShowSignModal}
            />
          )}
          <div className="col-12 row mb-2">
            <label className="font-weight-normal col-12">Completed Date</label>
            {/* <span className="font-danger">*</span></label> */}
            <input
              required={status === 'Completed'}
              disabled={status !== 'Completed'}
              type="datetime-local"
              className="form-control"
              value={completed_date}
              onChange={handleCompletedDate}
            />
          </div>
          {status === 'Completed' && (
            <div className="col-12 row mb-2 pt-2">
              <label className="font-weight-normal col-12">Completion Notes</label>
              <textarea
                className="form-control mb-2"
                name="completionNotes"
                id="completionNotes"
                placeholder="Add Completion Notes"
                onChange={handelCompletionNotes}
                value={completionNotes}
              ></textarea>
            </div>
          )}
          <hr />
          <div className='row'>
            <div className="card-title pl-2 col-sm-6 col-xs-12">
              <b>Time Log</b>{' '}
              <span>
                {totalHours} hr and {min} Min
              </span>
            </div>
            <div className="card-title pl-2 col-sm-6 col-xs-12">
              <b>Total Labor Cost</b>
              <strong className="red" id="total_labor_cost">
                {' '}
                ${totalCost}
              </strong>
              <input type="hidden" id="value_labor_cost" value="0" />
            </div>
          </div>
        </div>

        <div className="card-body p-0">
          {!showEdit && (
            <div className="col-12 mb-2 p-0">
              <i className="col-12">How much time was spent on this work order?</i>
              <div className="col-12">
                <i className="fa fa-clock-o"></i>
                <input
                  type="number"
                  min="0"
                  className="form-control "
                  style={{ width: '4em', display: 'inline' }}
                  name="workdone_h"
                  id="workdone_h"
                  placeholder="Hours Done"
                  value={hours}
                  onChange={handleHours}
                />{' '}
                <strong className="red">hr</strong>
                <input
                  type="number"
                  min="0"
                  className="form-control "
                  style={{ width: '4em', display: 'inline' }}
                  name="workdone_m"
                  id="workdone_m"
                  placeholder="Minutes Done"
                  value={minutes}
                  onChange={handleMinutes}
                />{' '}
                <strong className="red">Min</strong> to{' '}
                <div style={{ width: '300px', display: 'inline-block', margin: '5px' }}>
                  <SelectPersons
                    customForLogTime
                    teams={teams}
                    assignedTo={assigned_to}
                    setAssignedTo={setCreatedBy}
                  />
                </div>
                <div className='col-lg-12 col-sm-12 row' style={{ margin: '5px' }}>
                  on{' '}
                  <input
                    type="datetime-local"
                    className="form-control"
                    name="work_date"
                    id="work_date"
                    placeholder="Work Date"
                    style={{ width: '80%', marginLeft: "10px" }}
                    value={work_date}
                    onChange={handleWorkDate}
                  />
                </div>
              </div>
              <span
                className="font-danger float-left col-lg-12 col-md-12 col-sm-12"
                id="timelog_error"
              ></span>
              <div className="pl-2">
                <textarea
                  className="form-control mb-2"
                  name="work_description"
                  id="work_description"
                  placeholder="Add description"
                  value={description}
                  onChange={handelDescription}
                ></textarea>
                Hourly Rate{' '}
                <input
                  name="hourly_rate"
                  type="number"
                  min="0"
                  value={hourly_rate}
                  className="form-control"
                  style={{ width: '5em', display: 'inline' }}
                  id="hourly_rate"
                  placeholder="$0"
                  onChange={handleHourlyRate}
                />{' '}
                {isError && <span className="red">Please Add A Positive Numbers</span>}
                <button
                  id="timelog_button"
                  name="timelog_button"
                  className="btn btn-primary ml-2"
                  style={{ height: '2.5em', float: 'right' }}
                  onClick={handelAddTime}
                  type="button"
                >
                  <span className="button__text">Add Time</span>
                </button>
              </div>
            </div>
          )}

          <hr />
          <div className="col-12 row">
            <div className={logs?.length === 0 ? 'mb-5' : 'mb-2'}>
              <b>Log History</b>
            </div>
          </div>
          <div id="timelog_history">
            <input type="hidden" id="edit-timelog-id" />
            {logs &&
              logs.map((log) => {
                return (
                  <div key={log.id} className="col-12 p-1 mb-2" id="log-view-history.id">
                    {!showEdit && (
                      <>
                        <div>
                          <i className="fa fa-clock-o"></i>
                          <strong className="red">{log.hours} hr </strong> and{' '}
                          <strong className="red">{log.minutes} min</strong> to{' '}
                          <strong className="red">{log?.assigned_to?.name}</strong> on{' '}
                          <strong className="red">
                            {moment(log?.work_date).format('MMMM Do YYYY, h:mm:ss a')}
                          </strong>
                          {!showEdit && user?.id === log?.created_by?.id && (
                            <button
                              type="button"
                              title="Edit"
                              className=" ml-auto float-right secondary-btn btn"
                              onClick={() => handelEdit(log)}
                            >
                              <b>Edit</b>
                            </button>
                          )}
                        </div>
                        <div className="p-2">{log.description}</div>
                        <div className="pl-2">
                          Labor Cost: <strong className="red">${log.labor_cost}</strong>
                        </div>
                      </>
                    )}
                    {log.id === showEdit && (
                      <div className="col-12 p-1 mb-2">
                        <div>
                          <input
                            type="number"
                            min="0"
                            className="form-control "
                            style={{ width: '4em', display: 'inline' }}
                            placeholder="Hours Done"
                            defaultValue={hours || log.hours}
                            onChange={handleHours}
                          />{' '}
                          <strong className="red">hr</strong>{' '}
                          <input
                            type="number"
                            min="0"
                            className="form-control "
                            style={{ width: '4em', display: 'inline' }}
                            placeholder="Minutes Done"
                            defaultValue={minutes || log.minutes}
                            onChange={handleMinutes}
                          />{' '}
                          <strong className="red">Min</strong> to{' '}
                          <strong className="red"> {log?.assigned_to?.name} </strong> on
                          <input
                            type="datetime-local"
                            className="form-control "
                            placeholder="Work Date"
                            onChange={handleWorkDate}
                            defaultValue={
                              moment(log?.work_date).format('YYYY-MM-DDTHH:mm') ||
                              moment(log.work_date).format('YYYY-MM-DDTHH:mm')
                            }
                            style={{ width: '10em', display: 'inline' }}
                          />
                        </div>
                        <div className="p-2">
                          <textarea
                            className="form-control mb-2"
                            placeholder="Task Description"
                            onChange={handelDescription}
                            defaultValue={description || log.description}
                          ></textarea>
                          {isError && <span className="red">Please Add A Positive Numbers</span>}
                        </div>
                        <div className="btn">
                          <button
                            onClick={() => handelDelete(log.id)}
                            type="button"
                            data-toggle="modal"
                            data-target="#modal-timelog"
                            title="Delete Time Log"
                            className="fas fa-trash float-right mx-2 my-1 red"
                          ></button>
                          <button
                            onClick={handelCancel}
                            type="button"
                            title="Cancel"
                            id="log-view-btn-history.id"
                            className=" ml-auto float-right"
                          >
                            <b>Cancel</b>
                          </button>

                          <button
                            type="button"
                            title="Save"
                            className=" ml-auto float-right"
                            onClick={() => handelUpdate(log.id)}
                          >
                            <i className="fas fa-check mx-2 my-1 red"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* {workOrderApprovalModalAccept && (
        <NewModal
          showModal={workOrderApprovalModalAccept}
          handleClose={() => dispatch(closeWorkOrderApprovalModalAccept())}
          handleCreateSave={handleAccept}
          primaryButtonText="Accept"
          modalTitle="Accept this work order submission?"
        >
          System will send a notification to the requester and the task will move to “Accepted” tabs
        </NewModal>
      )} */}
      {workOrderApprovalModalDecline && (
        <div className='modal-wrapper'>
          <NewModal
            showModal={workOrderApprovalModalDecline}
            handleClose={() => dispatch(closeWorkOrderApprovalModalDecline())}
            handleCreateSave={handleDecline}
            primaryButtonText="Decline"
            modalTitle="Decline this work order submission?"
          >
            <div className="col-lg-12 col-sm-12" onClick={(e) => e.stopPropagation()}>
              <textarea
                className="form-control mb-2"
                name="description"
                id="meter_description"
                placeholder="Please state your reason*"
                onChange={handelReason}
                value={approvalReason}
              ></textarea>
            </div>
            <div className="col-lg-12 col-sm-12">
              This message will be included in the assigned person's notification for their review.
            </div>
          </NewModal>
        </div>
      )}
    </div>
  );
}

export default StatusTimeLog;
