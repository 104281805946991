/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowTurnDown, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux'
import trachIcoN from "../../../../../assets/img/trashIcon.png"
import trachIcoNGray from "../../../../../assets/img/trachIconGray.png"
import plusIcon from "../../../../../assets/img/plusIcon.png"
import { createChecklist, deleteBulkChecklist, deleteChecklistItem, reset, setTicketChecklists, updateChecklist, updateChecklistItem, updateChecklistItemAttachment } from '../../../../../redux/features/ticketChecklist/ticketChecklistSlice'
import { getPagination } from '../../../../../redux/features/checklist/checklistSlice';
import NewModal from '../../../../../components/modals/NewModal';
import AttachmentIcon from '../../../../../assets/svg-icons/AttachmentIcon';
import FileIcon from '../../../../../assets/svg-icons/FileIcon';



function PreviewTask({ item }) {
  const dispatch = useDispatch();
  const { ticketChecklists, isResultUpdateSuccess, itemResult } = useSelector((state) => state.ticketChecklist);

  const [status, setStatus] = useState(item?.result || "");
  const [isCommentOpend, setIsCommentOpend] = useState(item?.remarks ? true : false);
  const [comment, setComment] = useState(item?.remarks || "");
  const [isAttachmentOpend, setIsAttachmentOpend] = useState(item?.attachment ? true : false);
  const [file, setFile] = useState(item?.attachment || null);
  const [isDragging, setIsDragging] = useState(false);
  const [change, setChange] = useState(false);
  const [changeAttachment, setChangeAttachment] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    if (isResultUpdateSuccess) {
      const tickcheck = ticketChecklists.map(tck => {
        if (tck?.id === itemResult?.checklist_id) {
          const items = tck.items.map(task => {
            if (task.id === itemResult.id) {
              return { ...task, ...itemResult };
            }
            return task;
          });
          return { ...tck, items };
        }
        return tck;
      });
      dispatch(setTicketChecklists(tickcheck));
    }
  }, [isResultUpdateSuccess]);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setIsDragging(false);
    setChangeAttachment(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    setIsDragging(false);
    setChangeAttachment(true);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setChange(true);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setChange(true);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const sendingResult = async () => {
        if (change) {
          await dispatch(updateChecklistItem({ id: item?.id, result: status, remarks: comment, name: item?.name }));
          dispatch(reset());
          setChange(false);
        }
      };
      sendingResult();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [status, comment, change]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const sendingResult = async () => {
        if (changeAttachment) {
          const formData = new FormData();
          formData.append("attachment", file);
          formData.append("id", item?.id);
          await dispatch(updateChecklistItemAttachment({ id: item?.id, formData }));
          dispatch(reset());
          setChangeAttachment(false);
        }
      };
      sendingResult();
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [changeAttachment, file]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className='task-preview-container'>
      <div className='adjust-group p-0 mb-0'>
        <div className='group-name'>
          {item.name}
        </div>
        <div className='checklist-select-active'>
          <select onChange={handleStatusChange} value={status}>
            <option value="" disabled selected>Status</option>
            {item.type === "typeA" && <option value="Done">Done</option>}
            {item.type === "typeA" && <option value="Not Done">Not Done</option>}
            {item.type === "typeB" && <option value="Passed">Passed</option>}
            {item.type === "typeB" && <option value="Failed">Failed</option>}
            <option value="Pending">Pending</option>
            <option value="On hold">On hold</option>
          </select>
        </div>
        <div className={`cursor-pointer ml-4 ${isCommentOpend ? "icon-preview-active" : "icon-preview"}`} onClick={() => setIsCommentOpend(!isCommentOpend)}>
          <FileIcon />
        </div>
        <div className={`cursor-pointer ml-2 ${isAttachmentOpend ? "icon-preview-active" : "icon-preview"}`} onClick={() => setIsAttachmentOpend(!isAttachmentOpend)}>
          <AttachmentIcon />
        </div>
      </div>
      <div className='checklist-attachment-wrapper'>
        {isAttachmentOpend && (
          <div
            className={`file-upload-task-preview ${isDragging ? 'dragging' : ''} ${item?.attachment ? 'without-border' : ''}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id={`file-upload-input-${item.id}`} // Make ID unique
              accept="image/*"
            />
            {item?.attachment ?
              <div className='attached-div'>
                <img src={item?.attachment} alt="Attachment"
                  className='attached-img'
                  style={{ cursor: 'pointer' }}
                  onClick={toggleModal}
                />
              </div>
              :
              <label htmlFor={`file-upload-input-${item.id}`}>
                {isDragging ? 'Drop here' :
                  <>
                    <span>Choose/drop file</span>
                    <span>max 5mb </span>
                    <span>jpg/png format</span>
                  </>
                }
              </label>
            }
          </div>
        )}
        {isCommentOpend && (
          <textarea
            name="Enter a notes"
            className="form-control p-2"
            placeholder="Enter a notes"
            value={comment}
            onChange={handleCommentChange}
            id="description"
          ></textarea>
        )}
      </div>

      {/* Modal to display the image in original size */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content-custom">
            <img src={item?.attachment} alt="Attachment in original size" className="original-size-image" />
          </div>
        </div>
      )}
    </div>
  );
}

function ChecklistGroupPreview({ data }) {
  return <div>
    <div style={{ margin: "15px 0" }} >
      <h5 style={{ fontSize: "16px", fontWeight: "600" }} >{data?.title}</h5>
    </div>
    {data?.items?.map(item => {
      return <PreviewTask key={item?.id} data={data} item={item} />
    })}
  </div>
}

function ChecklistSearching({ checklistSearch, setchecklistSearch, handelChecklistSearchClick, checklistSearchBox, setchecklistSearchBox, ranges, handelEnterChecklist }) {
  const [filteredChecklist, setFilteredChecklist] = useState("")
  const checklists = useSelector((state) => state?.checklists?.checklists?.results);
  useEffect(() => {
    const rangesIds = ranges?.map(item => item?.title) || []
    setFilteredChecklist(checklists.filter(item => rangesIds?.includes(item?.title) === false))
  }, [checklists])

  const dispatch = useDispatch()
  const handelChecklistSearch = async (e) => {
    setchecklistSearchBox(true)
    const { value } = e.target
    setchecklistSearch(value)
    await dispatch(
      getPagination({ rowPerPage: 10, currentPage: 1, searchValue: value }),
    );
    if (!value) {
      setchecklistSearchBox(false)
    }
  }
  const handleEnter = (e) => {
    if (e.key === 'Enter' && checklistSearch) {
      handelEnterChecklist(checklistSearch);
      setchecklistSearch("")
    }
  }

  const handelOpenbox = () => {
    setchecklistSearchBox(!checklistSearchBox)
  }

  return <div className='Checklist-Searching'>
    <div className="Checklist-Searching-box">
      <input type="text" placeholder='Search or add new checklist'
        value={checklistSearch}
        onChange={handelChecklistSearch}
        onKeyPress={handleEnter}
      />
      <span onClick={handelOpenbox}>
        {checklistSearchBox ? <span style={{ fontSize: "28px", color: "#f63854" }}>-</span> : <img src={plusIcon} alt="plusIcon" />}
      </span>
    </div>
    {checklistSearchBox && <div className='tck-overlay' onClick={() => setchecklistSearchBox(!checklistSearchBox)} />}
    {checklistSearchBox && <div className='Checklist-Searching-results'>
      {filteredChecklist?.length > 0 ? filteredChecklist?.map(checklist => {
        return <div className='Checklist-Searching-results-result'
          onClick={() => handelChecklistSearchClick(checklist)}>
          <h6>{checklist?.title}</h6>
          <span>{checklist?.items_count} Tasks</span>
        </div>
      }) : <div style={{ textAlign: "center" }}>Press enter to create checklist</div>}
    </div>}
  </div>
}

function SelectStatusType({ setStatusType, handelStatusClick }) {
  const [isShowTypes, setIsShowTypes] = useState(false)
  return <div className='Status-Type'>
    <div className="Status-Type-box"
      onClick={() => setIsShowTypes(!isShowTypes)}
    >
      <p className='mb-0'>Status type</p>
      <span className={isShowTypes ? "rotateIcon" : ''}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
    </div>
    {isShowTypes && <div className='tck-overlay' onClick={() => setIsShowTypes(!isShowTypes)} />}
    {isShowTypes && <div className='Status-Type-results'>
      <div className='Status-Type-results-result' onClick={() => {
        setStatusType("typeA")
        setIsShowTypes(false)
        handelStatusClick("typeA")
      }}>
        <h6>Status type A</h6>
        <span>Options (Done/Not done/Pending/On hold)</span>
      </div>
      <div className='Status-Type-results-result mt-2' onClick={(e) => {
        setStatusType("typeB")
        setIsShowTypes(false)
        handelStatusClick("typeB")
      }}>
        <h6>Status type B</h6>
        <span>Options (Passed/Failed/Pending/On hold)</span>
      </div>
    </div>
    }
  </div>
}

function TaskEdit({
  data,
  item,
  setDeletedIds,
  setDeletePopUp,
  setModalBodyMsg,
  setModalTitle,
}) {

  const [taskValue, setTaskValue] = useState(item?.name)

  const handelDeleteTask = (obj) => {
    setDeletedIds(obj)
    setDeletePopUp("DeleteTask")
    setModalBodyMsg("Deleting this checklist will also remove all associated data")
    setModalTitle("Delete this tasks?")
  }

  const dispatch = useDispatch()
  const { ticketChecklists, isResultUpdateSuccess, itemResult } = useSelector((state) => state.ticketChecklist);
  useEffect(() => {
    if (isResultUpdateSuccess) {
      const tickcheck = ticketChecklists.map(tck => {
        if (tck?.id === itemResult?.checklist_id) {
          const items = tck.items.map(task => {
            if (task.id === itemResult.id) {
              return { ...task, ...itemResult };
            }
            return task;
          });
          return { ...tck, items };
        }
        return tck;
      });
      dispatch(setTicketChecklists(tickcheck));
    }
  }, [isResultUpdateSuccess]);


  const handelUpateItem = async (e) => {
    if (e.key === "Enter" && taskValue) {
      await dispatch(updateChecklistItem(
        { id: item?.id, name: taskValue, result: item.result, remarks: item.remarks, type: item?.type }
      ));
      dispatch(reset());
    }
  }

  return <div className='tasks-wraper-task'>
    <div className='task-name'>
      <input type="text" value={taskValue}
        onChange={(e) => setTaskValue(e.target.value)}
        placeholder='Task'
        onKeyPress={handelUpateItem}
      />
    </div>
    <div className='ml-4'>
      <div
        style={{ cursor: "pointer" }}
        id="add_check_list_trash"
        onClick={() => handelDeleteTask({ itemId: item?.id, checklistId: data.id })}
        title="Delete Task">
        <img style={{ width: "15px" }}
          src={trachIcoN} alt="trachIcon" />
        <div id="add_check_list_trash"> </div>
      </div>
    </div>
  </div>
}



function ChecklistGroupEdit(
  { data,
    handleSelectRange,
    ranges,
    setDeletedIds,
    setDeletePopUp,
    setModalBodyMsg,
    setModalTitle,
  }) {
  const dispatch = useDispatch()
  const { maintenance } = useSelector(state => state?.maintenance);

  const [taskName, setTaskName] = useState("")
  const [statusType, setStatusType] = useState("")


  const handleAddTask = async (e, checklistId) => {
    const rangesWithKey = ranges?.length > 0 ? ranges.map(range => {
      const items = range.items.map(item => {
        return { ...item, key: item.id }
      })
      return { ...range, items }
    }) : []

    const { value } = e.target
    if (e.key === 'Enter' && value) {
      const putData = rangesWithKey.map(range => {
        if (range?.id === checklistId) {
          return { ...range, items: [...range.items, { name: value, type: statusType || "typeA" }] }
        }
        return range
      })
      await dispatch(updateChecklist({ id: maintenance?.id, checklistData: putData }));
      setTaskName("")
    }
    setStatusType("")
  }

  const handelStatusClick = async (status, checklistId) => {
    const rangesWithKey = ranges?.length > 0 ? ranges.map(range => {
      const items = range.items.map(item => {
        return { ...item, key: item.id }
      })
      return { ...range, items }
    }) : []
    if (taskName) {
      const putData = rangesWithKey.map(range => {
        if (range?.id === checklistId) {
          return { ...range, items: [...range.items, { name: taskName, type: status || "typeA" }] }
        }
        return range
      })
      await dispatch(updateChecklist({ id: maintenance?.id, checklistData: putData }));
      setTaskName("")
    } else {
      toast.warning('Please fill the task then try again', {
        toastId: 'CreatedEventWarning',
      });
    }
  }

  const handelDeleteChecklist = (checklistId) => {
    setDeletedIds([checklistId])
    setDeletePopUp("DeleteBySelect")
    setModalBodyMsg("Deleting this checklist will also remove all associated tasks")
    setModalTitle("Delete this checklist?")
  }

  return <div>
    <div className="checkbox-wrapper-title mt-2 mb-2">
      <div className='flex'>
        <input
          type="checkbox"
          checked={data?.selected}
          onChange={() => handleSelectRange(data.id)}
        />
        <h6 style={{ fontWeight: "600", marginBottom: "0", marginTop: "3px" }}>{data?.title}</h6>
      </div>
      <button className="new-btn"
        style={{ borderRadius: "5px", padding: "5px" }}
        type='button'
        onClick={() => handelDeleteChecklist(data?.id)}
      >
        <img style={{ width: "15px" }}
          src={trachIcoN} alt="trachIcon" />
      </button>
    </div>
    <div className='tasks-wraper'>
      {data?.items?.map((item) => {
        return <TaskEdit
          data={data}
          item={item}
          handleAddTask={handleAddTask}
          setDeletedIds={setDeletedIds}
          setDeletePopUp={setDeletePopUp}
          setModalBodyMsg={setModalBodyMsg}
          setModalTitle={setModalTitle}
        />
      })}
      <div className="add-task-input">
        <input
          type="text"
          placeholder="Add additional tasks"
          onKeyPress={(e) => handleAddTask(e, data?.id)}
          onChange={(e) => setTaskName(e.target.value)}
          value={taskName}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <SelectStatusType
            setStatusType={setStatusType}
            handelStatusClick={(type) => handelStatusClick(type, data?.id)}
          /> */}
          <span> <FontAwesomeIcon icon={faArrowTurnDown} /></span>
        </div>
      </div>
    </div>
  </div>
}




function Checklist() {
  const {
    ticketChecklists,
  } = useSelector((state) => state?.ticketChecklist);
  const dispatch = useDispatch()
  const [ranges, setRanges] = useState([]);
  const [isEditing, setIsEditing] = useState(false)
  const [checklistSearch, setchecklistSearch] = useState("")

  const { maintenance } = useSelector(state => state?.maintenance);
  console.log("isEditing", isEditing);


  useEffect(() => {
    setIsEditing(ticketChecklists?.length > 0 ? false : true)
  }, [])


  useEffect(() => {
    setRanges(ticketChecklists.map(item => {
      return { ...item, selected: false }
    }) || [])
  }, [ticketChecklists])


  console.log("ranges", ranges);

  const [checklistSearchBox, setchecklistSearchBox] = useState(false)


  const handelChecklistSearchClick = async (checklist) => {
    const rangesWithKey = ranges?.length > 0 ? ranges.map(range => {
      const items = range.items.map(item => {
        return { ...item, key: item.id }
      })
      return { ...range, items }
    }) : []
    const data = [...rangesWithKey, {
      title: checklist?.title,
      items: checklist?.items,
      is_template: false
    }]
    if (ticketChecklists?.length > 0) {
      await dispatch(updateChecklist({ id: maintenance?.id, checklistData: data }));
    } else {
      await dispatch(
        createChecklist({
          id: maintenance?.id,
          checklistData: data,
        }),
      );
    }
    setchecklistSearchBox(false)
    setchecklistSearch("")

  }
  const handelEnterChecklist = async (checklistName) => {
    const rangesWithKey = ranges?.length > 0 ? ranges.map(range => {
      const items = range.items.map(item => {
        return { ...item, key: item.id }
      })
      return { ...range, items }
    }) : []
    const data = [...rangesWithKey, {
      title: checklistName,
      items: [],
      is_template: false
    }]

    if (ticketChecklists?.length > 0) {
      await dispatch(updateChecklist({ id: maintenance?.id, checklistData: data }));
    }
    else {
      await dispatch(
        createChecklist({
          id: maintenance?.id,
          checklistData: data,
        }),
      );
    }
    setchecklistSearchBox(false)
  }

  const handleSelectAll = (e) => {
    const isSelected = e.target.checked;
    setRanges(ranges.map(range => {
      return {
        ...range,
        selected: isSelected
      }
    }));
  };

  const [deletedIds, setDeletedIds] = useState(null)
  const [deletePopUp, setDeletePopUp] = useState("")
  const [modalbodyMsg, setModalBodyMsg] = useState("")
  const [modalTitle, setModalTitle] = useState("")

  const handelDeleteBySelect = () => {
    const deleteIds = []
    ranges.forEach(range => {
      if (range.selected) {
        deleteIds.push(range.id)
      }
    })
    if (deleteIds.length > 0) {
      setDeletedIds(deleteIds)
      setDeletePopUp("DeleteBySelect")
      setModalBodyMsg("Deleting this checklist will also remove all associated tasks")
      setModalTitle("Delete this checklist?")
    } else {
      toast.warning('Please select the checklist first', {
        toastId: 'CreatedEventWarning',
      });
    }
  };


  const handleDelete = () => {
    if (deletePopUp === "DeleteBySelect") {
      dispatch(deleteBulkChecklist(
        { ids: deletedIds }
      ))
    } else {
      dispatch(deleteChecklistItem(deletedIds))
    }
    setDeletePopUp("")
    setDeletedIds(null)
    setModalBodyMsg("")
    setModalTitle("")
  }




  const handleSelectRange = (rangeId) => {
    setRanges(ranges.map(range =>
      range.id === rangeId
        ? {
          ...range,
          selected: !range.selected,
        }
        : range
    ));
  };


  return (
    <div id="checklist-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-checklist-tab">
      <div>
        <div className="col-lg-12 col-sm-12 pb-5" id="">
          <div className="card-box card-body mx-auto col-lg-12 pb-5" id="CL-view">
            <div className='checklist-header'>
              <div>
                <div style={{ fontWeight: "600" }}> Checklist</div>
                <p>{ranges?.length} Checklist issued</p>
              </div>
              <button type="button"
                onClick={() => setIsEditing(!isEditing)}
                className='new-btn btn'
                style={{
                  color: "#f63854", borderRadius: "5px",
                  marginRight: "0px", fontWeight: "600",
                  fontSize: "14px"
                }}
              >
                {isEditing ? "Preview" : "Edit Checklist"}
              </button>
            </div>
            {isEditing ? <>
              <div className='checklist-drop-down'>
                <ChecklistSearching
                  handelChecklistSearchClick={handelChecklistSearchClick}
                  checklistSearchBox={checklistSearchBox}
                  setchecklistSearchBox={setchecklistSearchBox}
                  ranges={ranges}
                  handelEnterChecklist={handelEnterChecklist}
                  checklistSearch={checklistSearch}
                  setchecklistSearch={setchecklistSearch}
                />
                <div style={{ color: "#999999", marginTop: "5px", fontSize: "12px", fontStyle: "italic" }}>
                  *Enter a checklist name to create a new custom checklist
                </div>
              </div>
              {ranges?.length !== 0 &&
                <div className="select-all">
                  <div className="checkbox-wrapper-title">
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={ranges?.every(range => range?.selected) && ranges?.length !== 0}
                    />
                    <h6 className='mt-2'>Select all</h6>
                  </div>
                  <button className="new-btn"
                    style={{ borderRadius: "5px", padding: "5px" }}
                    type='button'
                    onClick={handelDeleteBySelect}
                  >
                    <img style={{ width: "15px" }}
                      src={trachIcoNGray} alt="trachIcon" />
                  </button>
                </div>
              }
              <div style={{ width: '100%', marginBottom: "20px", marginTop: "20px" }}>
                {ranges && ranges.length > 0 ? ranges.map((data) => (
                  <ChecklistGroupEdit
                    data={data}
                    handleSelectRange={handleSelectRange}
                    ranges={ranges}
                    setDeletedIds={setDeletedIds}
                    setDeletePopUp={setDeletePopUp}
                    setModalBodyMsg={setModalBodyMsg}
                    setModalTitle={setModalTitle}
                  />
                )) :
                  <div className='wrapper-no-checklist'>
                    <p className='no-checklist'>No checklist issued yet</p>
                  </div>
                }
                {deletePopUp && (
                  <NewModal
                    showModal={deletePopUp}
                    handleClose={() => setDeletePopUp("")}
                    handleCreateSave={handleDelete}
                    primaryButtonText="Delete"
                    modalTitle={modalTitle}
                  >
                    {modalbodyMsg}
                  </NewModal>
                )}
              </div>
            </>
              :
              <div style={{ width: '100%', marginBottom: "20px", marginTop: "20px" }}>
                {ranges && ranges.length > 0 ? ranges.map((data) => (
                  <ChecklistGroupPreview key={data?.id} data={data} />
                )) :
                  <div className='wrapper-no-checklist'>
                    <p className='no-checklist'>Nothing to preview</p>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checklist